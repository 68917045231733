<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div v-if="!incentiveProgram.id" class="col-lg-12 d-inline-flex mb-3">
          <div class="col-lg-4">
            <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
            <Multiselect
              ref="country"
              v-model="incentiveProgram.ccf"
              :searchable="false"
              :options="countries"
              label="name"
              track-by="value"
              :loading="!countries"
              :allow-empty="false"
              :show-labels="false"
            ></Multiselect>
          </div>
          <div class="col-lg-4">
            <label class="text-label">Fiscal Year</label>
            <Multiselect
              ref="year"
              v-model="incentiveProgram.fiscalYear"
              :searchable="false"
              :options="years"
              label="name"
              track-by="value"
              :loading="!years"
              :allow-empty="false"
              :show-labels="false"
            ></Multiselect>
          </div>
          <div class="col-lg-4">
            <label class="text-label">Quarter</label>
            <Multiselect
              ref="quarter"
              v-model="incentiveProgram.quarter"
              :searchable="false"
              :options="quarters"
              label="name"
              track-by="value"
              :loading="!quarters"
              :allow-empty="false"
              :show-labels="false"
            ></Multiselect>
          </div>
        </div>
        <div class="col-lg-12"> 
          <div class="col-lg-12">
            <span v-if="formErrors['form']" class="invalid-field">{{
              formErrors['form']
            }}</span>
          </div>
          <div class="col-lg-12 mb-sm-3 mt-3">
            <b><h4 v-text="$ml.get('incentive_add_form_objective') + ' 1'"></h4></b>
          </div> 
          <div class="col-lg-12 mb-sm-3">
            <label class="text-label" v-text="$ml.get('incentive_add_form_product_group')"></label>
            <Multiselect
              ref="productGroup"
              v-model="filteredProductGroup"
              :searchable="false"
              :options="productGroupList"
              label="productGroup"
              track-by="productGroup"
              :multiple="true"
              :loading="!productGroupList"
              :allow-empty="true"
              :taggable="true"
              :show-labels="false"
              @input="changeProductCategoryList($event, 'add')"
              @remove="changeProductCategoryList($event, 'remove')"
            ></Multiselect>
          </div> 
          <div class="col-lg-12 mb-sm-3">
            <label class="text-label" v-text="$ml.get('incentive_add_form_product_category')"></label>
            <Multiselect
              ref="productCategory"
              v-model="filteredProductCategory"
              :searchable="false"
              :options="productCategoryList"
              label="productCategory"
              track-by="productCategory"
              :multiple="true"
              :loading="!productCategoryList"
              :allow-empty="true"
              :show-labels="false"
            ></Multiselect>
          </div> 
          <div class="col-lg-12 mb-0 mt-3 d-inline-flex">
            <p style="color: #464a53" v-text="$ml.get('incentive_add_form_tiers')"></p>
            <i class="fa fa-plus-circle ml-2" @click="incentiveProgram.objective1List.length <= 4 && addTier()"></i>
          </div> 
          <div class="col-lg-12">
            <span v-if="formErrors['tier']" class="invalid-field">{{
              formErrors['tier']
            }}</span>
          </div>
          <div v-if="incentiveProgram.objective1List.length > 0" class="col-lg-12 mb-0 pl-0 d-inline-flex">
            <div class="col-lg-5">
              <label class="text-label" v-text="$ml.get('incentive_add_form_tier_by_units')"></label>
            </div>
            <div class="col-lg-2 text-center">
              <label class="text-label" v-text="$ml.get('incentive_add_form_tier_rebate_rate')"></label>
            </div>
          </div> 
          <div v-if="incentiveProgram.objective1List.length > 0">
            <div 
              v-for="(tier, index) in incentiveProgram.objective1List" 
              :key="index" 
            >
              <div class="col-lg-12 mb-sm-3 pl-0 d-inline-flex"> 
                <div class="col-lg-5 d-inline-flex">
                  <label class="text-label mt-auto mb-2 ml-0 mr-4">Tier {{ tier.tierLevel + 1 }} (%)</label>
                  <div class="d-flex text-center" style="flex-direction: column; width: 30%">
                    <span class="text-label">>=</span>
                    <input v-model="tier.tierAchievementMin" type="number" step=".01" class="w-50 m-auto text-center" />
                  </div>
                  <span class="mt-auto mb-2 ml-0 mr-0 text-label"> - </span>
                  <div class="d-flex text-center" style="flex-direction: column; width: 30%">
                    <span v-if="index === incentiveProgram.objective1List.length - 1" class="text-label"> &lt;= </span>
                    <span v-else class="text-label"> &lt; </span>
                    <input v-model="tier.tierAchievementMax" type="number" step=".01" class="w-50 m-auto text-center" />
                  </div>
                </div>
                <div class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
                  <input v-model="tier.tierReward" class="mb-0 mb-0 ml-auto mr-auto text-center" type="number" step=".01" style="width: 60%" />
                </div>
              </div>
              <div class="col-lg-12">
                <span v-if="formErrors[tier.tierLevel]" class="invalid-field">{{
                  formErrors[tier.tierLevel]
                }}</span>
              </div>
            </div> 
          </div>
          <div class="col-lg-12 mb-sm-3 mt-3">
            <b><h4 v-text="$ml.get('incentive_add_form_objective') + ' 2'"></h4></b>
          </div> 
          <div class="col-lg-12 mb-0 mt-3 d-inline-flex">
            <p style="color: #464a53" v-text="$ml.get('form_outlet_focusProduct')"></p>
            <i class="fa fa-plus-circle ml-2" @click="addFocusProduct()"></i>
          </div> 
          <div class="col-lg-12">
            <span v-if="formErrors['model']" class="invalid-field">{{
              formErrors['model']
            }}</span>
          </div>
          <div class="col-lg-12 mb-0 mt-3 d-inline-flex">
            <VueBootstrap4Table
              :classes="classes"
              :rows="objective2focusProductList"
              :columns="columns"
              :config="config"
              :total-rows="total"
              :show-loader="true"
              @refresh-data="updateProductFamily"
            >
              <template slot="No" slot-scope="props">
                <div class="inner-data text-center">
                  <span>{{ props.row.vbt_id }}</span>
                </div>
              </template>
              <template slot="name" slot-scope="props">
                <div class="inner-data">
                  <input v-model="props.row.name" class="w-100 m-auto text-center" @input="changeValue(props.row.vbt_id, 'name', $event)" />
                </div>
              </template>
              <template slot="attainmentRate" slot-scope="props">
                <div class="inner-data">
                  <input v-model="props.row.attainmentRate" type="number" step=".01" class="w-50 m-auto text-center" @input="changeValue(props.row.vbt_id, 'attainmentRate', $event)" />
                </div>
              </template>
              <template slot="type" slot-scope="props">
                <div class="inner-data custom-control">
                  <input
                    v-model="props.row.type"
                    :name="props.row.id"
                    type="radio"
                    value="mtm"
                  />
                  <label class="custom-control-label" @click="changeType(props.row, props.row.vbt_id, 'mtm')">MTM</label>
                  <input
                    v-model="props.row.type"
                    type="radio"
                    :name="props.row.id"
                    value="model"
                  />
                  <label class="custom-control-label" @click="changeType(props.row, props.row.vbt_id, 'model')">Model</label>
                </div>
              </template>
              <template slot="count" slot-scope="props">
                <div class="inner-data d-inline-flex">
                  <div class="col-lg-3 m-auto">
                    <span class="text-center m-auto" style="color:#F70201; cursor: pointer" @click="toggleShowModal(props.row.vbt_id)">
                      {{ props.row.type === 'model' ? props.row.productFamily.length : props.row.mtm.length }}
                    </span>
                  </div>
                  <div class="col-lg-9">
                    <button class="btn btn-primary btn-xs w-100" @click="props.row.type ? toggleUploadModal($event, props.row.vbt_id) : addError($event);">
                      Upload
                    </button>
                    <button class="btn btn-primary btn-xs w-100" @click="props.row.type ? toggleSelectModal($event, props.row.vbt_id) : addError($event)">
                      Select
                    </button>
                  </div>
                </div>
              </template>
              <template slot="tierReward" slot-scope="props">
                <div class="inner-data text-center">
                  <input v-model="props.row.tierReward" type="number" step=".01" class="m-auto text-center" style="width: 90%" @input="changeValue(props.row.vbt_id, 'tierReward', $event)" />
                </div>
              </template>
              <template slot="sort-asc-icon">
                <i class="mdi mdi-sort-ascending"></i>
              </template>
              <template slot="sort-desc-icon">
                <i class="mdi mdi-sort-descending"></i>
              </template>
              <template slot="no-sort-icon">
                <i class="mdi mdi-sort-variant"></i>
              </template>
              <template slot="empty-results">
                <div class="bold-text">No Focus Product Found!</div>
              </template>
            </VueBootstrap4Table>
          </div> 
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-right">
          <button
            type="submit"
            class="btn btn-secondary pull-right mr-3"
            v-text="$ml.get('button_submit_text')"
          ></button>
          <button
            type="button"
            class="btn btn-light pull-right"
            @click="closeModal()"
            v-text="$ml.get('button_cancel_text')"
          >
          </button>
        </div>
      </div>
    </form>
    <div v-if="objective2focusProductList.length > 0">
      <BModal
        ref="select-model-modal"
        size="lg"
        hide-footer
        title="Model"
      >
        <select_model_modal 
          :country-id="incentiveProgram.countryId" 
          :product-group-list="productGroupList" 
          :submit="updateProductFamily"
          :objective-list="objective2focusProductList"
          :selected-row="selectedRow"
          :min="objective2focusProductList.minPrice"
          :max="objective2focusProductList.maxPrice"
          :change-value="changeValue"
        />
      </BModal>
      <BModal
        ref="select-mtm-modal"
        size="lg"
        hide-footer
        title="MTM"
      >
        <select_mtm_modal 
          :country-id="incentiveProgram.countryId" 
          :product-group-list="productGroupList" 
          :submit="updateProductFamily"
          :objective-list="objective2focusProductList"
          :selected-row="selectedRow"
          :min="objective2focusProductList.min"
          :max="objective2focusProductList.max"
        />
      </BModal>
      <BModal ref="show-mtm-model-modal" size="xl" hide-footer :title="objective2focusProductList[showModal - 1].type === 'model' ? 'Show Model' : 'Show MTM'">
        <incentive_show_mtm_model_modal 
          :list="objective2focusProductList[showModal - 1].type === 'model' ? objective2focusProductList[showModal - 1].productFamily : objective2focusProductList[showModal - 1].mtm"
          :min="objective2focusProductList[showModal - 1].minPrice"
          :max="objective2focusProductList[showModal - 1].maxPrice"
          :type="objective2focusProductList[showModal - 1].type"
          :country-id="incentiveProgram.countryId"
        />
      </BModal>
      <BModal ref="upload-modal" size="xl" hide-footer title="Upload">
        <div v-if="objective2focusProductList[selectedRow - 1].type === 'model'" class="row mb-3">
          <div class="col-lg-6 d-flex" style="flex-direction: column">
            <label>Min price</label>
            <input v-model="incentiveProgram.objective2List.minPrice" class="form-control" />
          </div>
          <div class="col-lg-6 d-flex" style="flex-direction: column">
            <label>Max price</label>
            <input v-model="incentiveProgram.objective2List.maxPrice" class="form-control" />
          </div>
        </div>
        <excelUploadForm
          :close-modal="toggleUploadModal"
          :validations="validateFields"
          :sample-file="objective2focusProductList[selectedRow - 1].type === 'mtm' ? sampleMTMFile : sampleModelFile"
          :content="content"
          :country-id="incentiveProgram.countryId"
          :objective-list="objective2focusProductList"
          :add-error="addMTMError"
          :type="objective2focusProductList[selectedRow - 1].type"
          :set-post-data="setPostData"
          :show-data-list="true"
          :submit-button-text="'Submit'"
          :get-upload-file="getUploadFile"
          :file-columns="objective2focusProductList[selectedRow - 1].type === 'model' ? fileModelColumnStructure : fileMTMColumnStructure"
          :file-data="excelSampleData"
          :template-file-columns="objective2focusProductList[selectedRow - 1].type === 'model' ? fileModelColumnStructureTemplate : fileMTMColumnStructureTemplate"
        />
        <div v-if="formErrors['mtm']" class="col-lg-12 mb-4">
          <span class="invalid-field">{{
            formErrors['mtm']
          }}</span>
        </div>
      </BModal>
    </div>
  </div>
</template>

<script>
import {
  INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST,
  INCENTIVE_PROMOTER_EDIT_REQUEST,
  INCENTIVE_PROMOTER_ADD_NEW
} from "../../store/modules/incentive/action";
import excelUploadForm from "../../components/excel_upload/index";
import { BModal } from "bootstrap-vue";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "../../store/modules/ui/actions";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import select_model_modal from "../../components/incentive/select_model_modal";
import select_mtm_modal from "../../components/incentive/select_mtm_modal";
import incentive_show_mtm_model_modal from "../../components/incentive/incentive_show_mtm_model_modal";
import { COUNTRY_LIST_REQUEST } from "../../store/modules/utility/actions";

export default {
  name: "AddIncentiveScheme",
  components: {
    Multiselect,
    BModal,
    VueBootstrap4Table: VueBootstrap4Table,
    select_model_modal,
    select_mtm_modal,
    incentive_show_mtm_model_modal,
    excelUploadForm: excelUploadForm
  },
  props: {
    selectedIncentiveProgram: {type: Object, default: () => {}},
    closeModal: {type: Function, default: () => {}},
    quarterOptions: {type: Array, default: () => {}},
    yearOptions: {type: Array, default: () => {}},
    countryOptions: {type: Array, default: () => {}},
  },
  computed: {
    filteredProductGroup: {
      get: function () {
        return this.incentiveProgram.productGroup.filter(el => el.value !== 0)
      },
      set: function (value) {
        if(value.find(el => el.value === 0)){
          this.incentiveProgram.productGroup = [];
          for (let item of this.productGroupList) {
            if (item.value !== 0) {
              this.incentiveProgram.productGroup.push(item);
              this.$emit('handle-select', item);
            }
          } 
        } else {
          this.incentiveProgram.productGroup = value;
        }
      }
    },
    filteredProductCategory: {
      get: function () {
        return this.incentiveProgram.productCategory.filter(el => el.value !== 0)
      },
      set: function (value) {
        if(value.find(el => el.value === 0)){
          this.incentiveProgram.productCategory = [];
          for (let item of this.productCategoryList) {
            if (item.value !== 0) {
              this.incentiveProgram.productCategory.push(item);
              this.$emit('handle-select', item);
            }
          } 
        } else {
          this.incentiveProgram.productCategory = value;
        }
      }
    }
  },
  data(){
    return{
      countriesId: [],
      sampleMTMFile: "MTMUpload",
      sampleModelFile: "ModelUpload",
      content:
        "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the column labels in the sample file and make sure the file extension is .xlsx file.",
      fileMTMColumnStructure: [
        "MTM",
        "Model",
        "Group",
        "Category",
        "SRP"
      ],
      fileMTMColumnStructureTemplate: [
        "MTM"
      ],
      fileModelColumnStructure: [
        "Model",
        "Group",
        "Category",
      ],
      fileModelColumnStructureTemplate: [
        "Model"
      ],
      excelSampleData: [],
      incentiveProgram: JSON.parse(JSON.stringify(this.selectedIncentiveProgram)),
      parentSelectedIncentiveProgram: this.selectedIncentiveProgram,
      productGroupList: [],
      productCategoryList: [],
      formErrors: {},
      objective2focusProductList: JSON.parse(JSON.stringify(this.selectedIncentiveProgram.objective2List)),
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "No",
          label: "#",
          width: "5%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-center",
        },
        {
          name: "name",
          label: "Name",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "attainmentRate",
          label: "Attainment Rate (Obj 1) (%)",
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "type",
          label: "Type",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "count",
          label: "Count",
          width: "25%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        },
        {
          name: "tierReward",
          label: "Incentive (%)",
          width: "10%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        }
      ],
      total: this.selectedIncentiveProgram.objective2List.length,
      selectedRow: 1,
      validMTM: null,
      countries: this.countryOptions,
      years: this.yearOptions,
      quarters: this.quarterOptions,
      showModal: 1,
    }
  },
  watch: {
    "incentiveProgram.ccf": function(){
      this.incentiveProgram.countryId = this.countriesId.find(el => el.code === this.incentiveProgram.ccf.value).id;
      this.filteredProductGroup = [];
      this.filteredProductCategory = [];
      this.getProductGroupList();
    }
  },
  methods: {
    addError(event){
      event.preventDefault();
      this.formErrors = {...this.formErrors};
      this.formErrors.model = 'Please choose a type first';
    },
    addMTMError(list, type){
      this.formErrors = {...this.formErrors};
      this.formErrors["mtm"] = `${list.length} ${type === 'mtm' ? 'MTM' : 'Model'} ${list.length === 1 ? 'is' : 'are'} duplicated and will not be upload`;
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;
      if((this.incentiveProgram.objective1List.length === 0 && (this.incentiveProgram.productCategory.length > 0 || this.incentiveProgram.productGroup.length > 0)) || 
        this.incentiveProgram.objective1List.length === 1 && 
        (this.incentiveProgram.objective1List[0].tierReward === "" || this.incentiveProgram.objective1List[0].tierReward === null) && 
        (this.incentiveProgram.productCategory.length > 0 || this.incentiveProgram.productGroup.length > 0)){
        this.formErrors["tier"] = "Please create at least one tier";
        error++;
      } else if(this.objective2focusProductList.length === 1 && 
        (this.objective2focusProductList[0].name === null || this.objective2focusProductList[0].name === "") &&
        (this.incentiveProgram.productCategory.length === 0 || this.incentiveProgram.productGroup.length === 0))
      {
        this.formErrors["form"] = "At least one objective must be fill in";
        error++;
      }
      this.incentiveProgram.objective1List.map((el, index) => {
        if(el.tierAchievementMax !== 0){
          if(
            Number(el.tierAchievementMin) > Number(el.tierAchievementMax) ||
            Number(el.tierAchievementMin) === Number(el.tierAchievementMax) 
          ){
            if(
              !(index === this.incentiveProgram.objective1List.length - 1 &&
              el.tierAchievementMax === null) && !(index === this.incentiveProgram.objective1List.length - 1 &&
              el.tierAchievementMax === "")
            ){
              this.formErrors[el.tierLevel] = "Maximum achivement number should be higher than the minimum achivement field";
              error++;
            }
          }
        }
        if(
          index !== 0 && 
          !(index === this.incentiveProgram.objective1List.length - 1 && 
            (el.tierAchievementMin === "" || el.tierAchievementMin === null) && 
            (el.tierAchievementMax === "" || el.tierAchievementMax === null) &&
            (el.tierReward === "" || el.tierReward === null)
          )){
          if(Number(el.tierAchievementMin) < Number(this.incentiveProgram.objective1List[index - 1].tierAchievementMax)){
            this.formErrors[el.tierLevel] = "The minimum for this tier level must be higher than a previous one";
            error++;
          }
        }
        if(
          !(index === this.incentiveProgram.objective1List.length - 1 &&
            (el.tierAchievementMin === "" || el.tierAchievementMin === null) && 
            (el.tierAchievementMax === "" || el.tierAchievementMax === null) && 
            (el.tierReward === "" || el.tierAchievementMax === null)
          )
        ){
          if(index !== this.incentiveProgram.objective1List.length - 1){
            if(el.tierAchievementMax === null || el.tierAchievementMax === ""){
              this.formErrors[el.tierLevel] = "Maximum achivement number can't be empty";
              error++;
            }
          }
          if(el.tierReward === null || el.tierReward === ""){
            this.formErrors[el.tierLevel] = "Rebate rate can't be empty";
            error++;
          }
          if(el.tierAchievementMin === null || el.tierAchievementMin === ""){
            this.formErrors[el.tierLevel] = "Minimum achivement number can't be empty";
            error++;
          }
        }
        if(el.tierReward < 0){
          this.formErrors[el.tierLevel] = "Rebate rate can't be negative value";
          error++;
        }
      });
      this.objective2focusProductList.map(el => {
        if(el.name || el.attainmentRate || el.type || el.tierReward){
          if(!el.name){
            this.formErrors["model"] = "Field name can't be empty";
            error++;
          }
          if(!el.attainmentRate){
            this.formErrors["model"] = "Attainment rate can't be empty";
            error++;
          } 
          if(el.attainmentRate < 0){
            this.formErrors["model"] = "Attainment rate can't be negative value";
            error++;
          } 
          if(el.type === null){
            this.formErrors["model"] = "Please select a type";
            error++;
          }
          if(el.mtm.length === 0 && el.productFamily.length === 0){
            this.formErrors["model"] = "Please select or upload mtm/model";
            error++;
          }
          if(el.tierReward === null){
            this.formErrors["model"] = "Incentive % can't be empty";
            error++;
          }
        }   
      })

      if (error) {
        return false;
      } else {
        return true;
      }
    },
    getUploadFile() {},
    validateFields(value, field) {
      let isValid = false;
      switch (field) {
        case "MTM":
          isValid = true;
          break;
        default:
          isValid = true;
          break;
      }

      if (!isValid) {
        value = '<span class="invalid-field">' + value + "</span>";
      }

      return {
        value: value,
        valid: isValid
      };
    },
    setPostData(pdata) {
      if (pdata.length > 0) {
        this.updateProductFamily(pdata, 'closed');
      }
    },
    toggleSelectModal(event, id){
      if(event){
        event.preventDefault();
        this.selectedRow = id;
      }
      if(this.objective2focusProductList[id - 1].type === 'model'){
        this.$refs["select-model-modal"].toggle(); 
      } else {
        this.$refs["select-mtm-modal"].toggle();
      }
    },
    toggleUploadModal(event, id){
      if(event){
        event.preventDefault();
        this.selectedRow = id;
      }
      delete this.formErrors["mtm"];
      this.$refs["upload-modal"].toggle();
    },
    toggleShowModal(id){
      this.showModal = id;
      this.$refs["show-mtm-model-modal"].toggle();
    },
    changeValue(id, name, event){
      if(
        name === 'minPrice' || 
        name === 'maxPrice' || 
        name === 'tierLevel' || 
        name === 'tierReward' || 
        name === 'attainmentRate'
      ){
        this.objective2focusProductList[id - 1][`${name}`] = Number(event.target.value);
      } else {
        this.objective2focusProductList[id - 1][`${name}`] = event.target.value;
      }
      // this.incentiveProgram.objective2List.find(el => el.id === id)[`${name}`] = event.target.value;
    },
    updateProductFamily(selected, modal){
      if(selected){
        if(this.objective2focusProductList[this.selectedRow - 1].type === 'model'){
          let selected_array = selected.map(element => element.model || element[0]);
          this.objective2focusProductList[this.selectedRow - 1].productFamily = selected_array;
          this.objective2focusProductList[this.selectedRow - 1].type = 'model';
          this.incentiveProgram.objective2List[this.selectedRow - 1].type = 'model';
          this.incentiveProgram.objective2List[this.selectedRow - 1].productFamily = selected_array;
          this.objective2focusProductList[this.selectedRow - 1].mtm = [];
          this.incentiveProgram.objective2List[this.selectedRow - 1].mtm = [];
          if(modal !== 'closed'){
            this.$refs["select-model-modal"].toggle()
          } else {
            this.$refs["upload-modal"].toggle()
          }
        } else {
          let selected_array = selected.map(element => element.mtm || element[0]);
          this.objective2focusProductList[this.selectedRow - 1].mtm = selected_array;
          this.objective2focusProductList[this.selectedRow - 1].type = 'mtm';
          this.incentiveProgram.objective2List[this.selectedRow - 1].type = 'mtm';
          this.incentiveProgram.objective2List[this.selectedRow - 1].mtm = selected_array;
          this.objective2focusProductList[this.selectedRow - 1].productFamily = [];
          this.incentiveProgram.objective2List[this.selectedRow - 1].productFamily = [];
          if(modal !== 'closed'){
            this.$refs["select-mtm-modal"].toggle(); 
          } else {
            this.$refs["upload-modal"].toggle()
          }
        }
      }
    },
    changeType(row, id, value){
      this.formErrors = {};
      row.type = value;
      this.objective2focusProductList[id - 1].type = value;
    },
    handleSubmit(){
      if(this.checkFormValidity()){
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_submitting")
        });
        let program = {...this.incentiveProgram};
        if(program.productCategory){
          program.productCategory = this.filteredProductCategory.map(elem => elem.productCategory || elem);
        }
        if(program.productGroup){
          program.productGroup = this.filteredProductGroup.map(elem => elem.productGroup || elem);
        }
        if(program.objective1List.filter(el => 
          (el.tierAchievementMin === null || el.tierAchievementMin === "") && 
          (el.tierAchievementMax === null || el.tierAchievementMax === "") && 
          (el.tierReward === null || el.tierReward === "")).length > 0)
        {
          program.objective1List = program.objective1List.filter(el => {
            if(!((el.tierAchievementMin === null || el.tierAchievementMin === "") && 
              (el.tierAchievementMax === null || el.tierAchievementMax === "") && 
              (el.tierReward === null || el.tierReward === ""))){
                return el;
              }
            }
          );
        }
        if(this.objective2focusProductList.filter(el => 
            (el.name === null || el.name === "") && 
            (el.attainmentRate === null || el.attainmentRate === "") && 
            (el.tierReward === null || el.tierReward === "")
          ).length > 0){
          this.objective2focusProductList = this.objective2focusProductList.filter(el => {
            if(!((el.attainmentRate === null || el.attainmentRate === "") && 
              (el.name === null || el.name === "") && 
              (el.tierReward === null || el.tierReward === ""))){
                return el;
              }
            }
          );
        }
        program.objective1List = program.objective1List.sort(function(a, b) {
          var keyA = Number(a.tierAchievementMin),
            keyB = Number(b.tierAchievementMin);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }).map((el, index) => {
          el.tierAchievementMin = Number(el.tierAchievementMin);
          el.tierAchievementMax = index === program.objective1List.length - 1 && (el.tierAchievementMax === "" || el.tierAchievementMax === null) ? null : Number(el.tierAchievementMax);
          el.tierReward = Number(el.tierReward);
          el.tierLevel = index;
          return el;
        })
        program.objective2List = this.objective2focusProductList;
        let request;
        if(this.incentiveProgram.id){
          request = INCENTIVE_PROMOTER_EDIT_REQUEST;
        } else {
          request = INCENTIVE_PROMOTER_ADD_NEW;
          program.ccf = program.ccf.value;
          program.fiscalYear = program.fiscalYear.value;
          program.quarter = program.quarter.value;
        }
        this.$store.dispatch(request, program).then(res => {
          if(res.content && res.resultCode === 0){
            this.parentSelectedIncentiveProgram.objective1List.push({});
            this.parentSelectedIncentiveProgram = this.incentiveProgram;
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Incentive is updated"
            });
            this.closeModal();
          }
        }).catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
        });
      }
    },
    getProductGroupList(){
      let countryId = this.incentiveProgram.countryId;
      if(countryId){
        this.$store.dispatch(INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST, countryId).then(res => {
          if(res.content && res.resultCode === 0){
            this.productGroupList = [];
            this.productGroupList.push({productGroup: "Select All", value: 0});
            res.content.forEach(el => this.productGroupList.push(el));
            if(this.incentiveProgram.productGroup){
              this.filteredProductGroup = this.incentiveProgram.productGroup.map(element => {
                return {productGroup: element, productCategoryList: this.productGroupList.find(item => item.productGroup === element).productCategoryList}
              });
            }
            if(this.incentiveProgram.productCategory){
              this.incentiveProgram.productCategory = this.incentiveProgram.productCategory.map(element => {
                return {productCategory: element, productModelList: this.productGroupList.filter(el => el.value !== 0).find(item => item.productCategoryList.map(el => { 
                  return el.productCategory === element
                }))}
              })
            }
            this.changeProductCategoryList(this.filteredProductGroup, "add")
          }
        });
      }
    },
    changeProductCategoryList(event, action){
      if(event){
        if(action === "add"){
          this.productCategoryList = [];
          this.productCategoryList.push({productCategory: "Select All", value: 0});
          if(event.find(el => el.value === 0)){
            this.productGroupList.filter(el => el.value !== 0).map(item => item.productCategoryList).flat().forEach(element => this.productCategoryList.push(element));
          } else {
            event.map(item => item.productCategoryList).flat().forEach(element => this.productCategoryList.push(element));
          }
        } else {
          this.productCategoryList = this.productCategoryList.filter( function( el ) {
            return !event.productCategoryList.includes( el );
          });
          this.incentiveProgram.productCategory = this.incentiveProgram.productCategory.filter( function( el ) {
            return !event.productCategoryList.find( l => l.productCategory === el.productCategory );
          });
        }
      }
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countriesId.push(country);
          });
        }
      });
    },
    addTier(){
      this.incentiveProgram.objective1List.push({
        "tierLevel": this.incentiveProgram.objective1List.length > 0 ? Math.max.apply(Math, this.incentiveProgram.objective1List.map(function(o) { return o.tierLevel; })) + 1 : 0,
        "tierAchievementMin": null,
        "tierAchievementMax": null,
        "tierReward": null
      })
    },
    addFocusProduct(){
      this.objective2focusProductList.push({
        "name": null,
        "attainmentRate": null,
        "productFamily": [],
        "tierReward": null,
        "type": null,
        "mtm": []
      });
      this.incentiveProgram.objective2List.push({
        "name": null,
        "attainmentRate": null,
        "productFamily": [],
        "tierReward": null,
        "type": null,
        "mtm": []
      })
    }
  },
  mounted() {
    this.countries = this.countryOptions.filter(el => el.value !== "CAP");
    this.incentiveProgram.objective1List.sort(function(a, b) {
      var keyA = Number(a.tierLevel),
        keyB = Number(b.tierLevel);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    this.getCountries();
    this.getProductGroupList();
  }
}
</script>