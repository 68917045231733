import { render, staticRenderFns } from "./turn_on_duration.vue?vue&type=template&id=a2527bbe&"
import script from "./turn_on_duration.vue?vue&type=script&lang=js&"
export * from "./turn_on_duration.vue?vue&type=script&lang=js&"
import style0 from "./turn_on_duration.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports