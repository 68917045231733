<template>
  <div id="dealer_sell_in">
    <breadcrumb title="Dashboard" subtitle="Dealer Sell Thru" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="mb-3 w-100 d-inline-flex">
                <div class="col-lg-6">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                    ref="year"
                    v-model="filters.yearSelected"
                    :searchable="false"
                    :options="yearOptions"
                    label="name"
                    track-by="value"
                    :loading="!yearOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getSellThruList"
                  ></Multiselect>
                </div>
                <div class="col-lg-6">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                    ref="quarter"
                    v-model="filters.quarterSelected"
                    :searchable="false"
                    :options="quarterOptions"
                    label="name"
                    track-by="value"
                    :loading="!quarterOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getSellThruList"
                  ></Multiselect>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 d-flex align-items-center justify-content-end">
                <div>
                  <button
                    type="button"
                    :disabled="!dataList"
                    class="btn btn-sm btn-primary"
                    @click="dealerSalesInModalToggle"
                  >
                    <i class="fa fa-upload"></i> Upload
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <VueBootstrap4Table
                  :classes="classes"
                  :rows="dataList"
                  :columns="columns"
                  :config="config"
                  :total-rows="total"
                  @on-change-query="onChangeQuery"
                >
                  <template slot="sort-asc-icon">
                    <i class="mdi mdi-sort-ascending"></i>
                  </template>
                  <template slot="sort-desc-icon">
                    <i class="mdi mdi-sort-descending"></i>
                  </template>
                  <template slot="no-sort-icon">
                    <i class="mdi mdi-sort-variant"></i>
                  </template>
                  <template slot="createdDate" slot-scope="props">
                    <span>{{ convertDate(props.row.createdDate, "YYYY-MM-DD hh:mm:ss") }}</span>
                  </template>
                  <template slot="status" slot-scope="props">
                    <span>{{ props.row.bValid ? "ACCEPTED" : "REJECTED" }}</span>
                  </template>
                  <template slot="empty-results">
                    <div
                      v-if="!isDataLoading"
                      class="bold-text"
                    >No Dealer Sell Thru Data Found!</div>
                    <div
                      v-if="isDataLoading"
                      class="loading mt-3"
                    ></div>
                  </template>

                  <template slot="edit" slot-scope="props">
                    <div>
                      <button
                        class="btn btn-warning btn-xs"
                        @click.prevent="
                          dealerSellInViewModalToggle(props.row.id)
                        "
                      >
                        View
                      </button>
                      <button
                        class="btn btn-primary btn-xs"
                        @click.prevent="
                          doDownload(
                            props.row.id,
                          )
                        "
                      >
                        <i class="fa fa-download"></i>
                        Download
                      </button>
                    </div>
                  </template>
                </VueBootstrap4Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BModal
      id="dealer-sell-in-upload"
      ref="dealer-sell-in-upload"
      size="lg"
      hide-footer
      title="Dealer Sell Thru Upload"
    >
      <div class="row">
        <div class="col-lg-12 d-flex flex-column">
          <div>
            <label class="text-label">Dealer Sell Thru File*</label>
          </div>
          <div class="d-flex">
            <div class="d-flex flex-column w-100">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <div class="w-100 d-flex align-items-center">
                    <div class="w-100">
                      <BFormFile
                        id="file"
                        ref="file"
                        v-model="fileUpload"
                        v-validate="'required'"
                        name="file"
                        accept=".xlsx"
                        size="lg"
                        class="cursor-pointer"
                        @change="handleFileUpload($event)"
                      ></BFormFile>
                    </div>
                    <div class="ml-4">
                      <button
                        type="button"
                        class="btn btn-primary btn-xs"
                        @click="resetUploadedFile()"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span class="invalid-field">{{
                      errors.first("file")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <button
                  type="button"
                  class="ml-0 p-0 mt-1 btn-link btn text-info width-fit-content"
                  @click="doExport()"
                >
                  Dealer Sell Thru template
                  <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                </button>
                <p class="text-muted">
                  Please upload the Mtm number and MDM Code available in the
                  system
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button
          type="btn"
          class="btn btn-primary btn-sm"
          @click.prevent="handleSubmit"
        >
          Confirm
        </button>
      </div>
    </BModal>
    <BModal
      ref="dealer-sell-in-view"
      size="lg"
      hide-footer
      title="Dealer Sell Thru View"
    >
      <div>
        <VueBootstrap4Table
          :classes="classes"
          :rows="salesSellInListSelected"
          :columns="dealerSellInPreviewColumns"
          :config="config"
          :total-rows="salesSellInListSelectedTotal"
        >
          <template slot="sort-asc-icon">
            <i class="mdi mdi-sort-ascending"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="mdi mdi-sort-descending"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="mdi mdi-sort-variant"></i>
          </template>
          <template slot="empty-results">
            <div
              v-if="!isDataViewLoading"
              class="bold-text"
            >No Dealer Sell Thru Selected</div>
            <div
              v-if="isDataViewLoading"
              class="loading mt-3"
            ></div>
          </template>
        </VueBootstrap4Table>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button
          type="btn"
          class="btn btn-primary btn-sm"
          @click="dealerSellInViewModalToggle"
        >
          Close
        </button>
      </div>
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { BModal, BFormFile } from "bootstrap-vue";
import { convertDateFormat, defaultFiscalYear, defaultQuarter } from "../../utils/helpers";
import XLSX from "xlsx";
import { export_json_to_excel } from "../../utils/Export2Excel";
import Multiselect from "vue-multiselect";

import {
  GET_SELL_THRU_UPLOADS_ALL,
  DEALER_SELL_TRHU_UPLOAD_REQUEST,
  DEALERS_SELL_THRU_UPLOAD_BY_ID,
  DOWNLOAD_DEALER_SELL_THRU_REQUEST
} from "../../store/modules/dealer/actions";

import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "../../store/modules/ui/actions";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";

export default {
  name: "DealerSellIn",
  components: {
    breadcrumb,
    VueBootstrap4Table,
    BModal,
    BFormFile,
    Multiselect: Multiselect
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Dealer Sales In",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          sort: true,
          visibility: false,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "fileName",
          label: "File Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "totalLines",
          label: "Total Line",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "createdDate",
          label: "Upload Date",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "edit",
          label: "Action",
          row_text_alignment: "text-center",
          column_text_alignment: "text-center"
        }
      ],
      dealerSellInPreviewColumns: [
        {
          name: "week",
          label: "Week",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "lppCode",
          label: "MDM Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "mtmNo",
          label: "MTM Number",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "sellThru",
          label: "Quantity",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Remarks",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      dataList: [],
      total: 0,
      yearOptions: [],
      quarterOptions: [],
      filters: {
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      },
      salesSellInListSelected: [],
      salesSellInListSelectedTotal: 0,
      fileUpload: [],
      search: {},
      sellIn: {
        fileName: "",
        salesSellInList: []
      },
      sellInErrors: {},
      tHeader: ["Week", "MDM Code", "Dealer Name", "MTM Number", "Quantity"],
      filterVal: ["week", "lppCode", "dealerName", "mtmNumber", "quantity"],
      isDataLoading: false,
      isDataViewLoading: false
    };
  },
  methods: {
    convertDate(date, format){
      return convertDateFormat(date, format);
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    getSellThruList(){
      this.isDataLoading = true;
      let pdata = {};
      pdata.fyf = this.filters.yearSelected.value;
      pdata.qf = this.filters.quarterSelected.value;
      this.$store.dispatch(GET_SELL_THRU_UPLOADS_ALL, pdata).then(res => {
        if (res.resultCode === 0) {
          this.dataList = res.content;
          this.total = res.content.length;
          this.isDataLoading = false;
        }
      }).catch(err => {
        console.log(err)
        this.isDataLoading = false;
      });
    },
    dealerSalesInModalToggle() {
      this.$refs["dealer-sell-in-upload"].toggle();
    },
    dealerSellInViewModalToggle(id) {
      this.isDataViewLoading = true;
      this.$store.dispatch(DEALERS_SELL_THRU_UPLOAD_BY_ID, id).then(res => {
        if (res.resultCode === 0) {
          this.salesSellInListSelected = res.content;
          this.salesSellInListSelectedTotal = res.content.length;
          this.isDataViewLoading = false;
        }
      })
      this.$refs["dealer-sell-in-view"].toggle();
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    resetUploadedFile() {
      this.$refs["file"].reset();
    },
    checkUploadType(fileName) {
      if (fileName) {
        let filename = fileName.split(".").pop();
        if (filename === "xlsx") {
          delete this.sellInErrors.file;
          return true;
        } else {
          this.sellInErrors.file = "File type must be .xlsx";
          return false;
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    checkFileHeaders(headers) {
      // Normalizing column heads for comparison
      const formattedHeaders = JSON.stringify(headers);
      const formattedTheader = JSON.stringify(this.tHeader);
      return formattedHeaders === formattedTheader;
    },
    dataAdjustmentsAfterUpload(sheet, name) {
      let formattedArrayParams = sheet.map(item => {
        return {
          lppCode: item["MDM Code"],
          mtmNumber: item["MTM Number"],
          dealerName: item["Dealer Name"],
          quantity: item["Quantity"],
          week: item["Week"]
        };
      });
      this.sellIn.salesSellInList = formattedArrayParams;
      this.sellIn.fileName = name;
    },
    doExport() {
      let sampleData = [""]
      const data = this.formatJson(this.filterVal, sampleData);
      const fileName = "dealer sell thru template";
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: fileName,
        sheetName: fileName,
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    doDownload(id) {
      this.$store.dispatch(DOWNLOAD_DEALER_SELL_THRU_REQUEST, id).then(res => {
        if (res.resultCode === 0) {
          window.open(res.content.link, '_blank');
        }
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result && Object.keys(this.sellInErrors).length === 0) {
          let pdata = {};
          pdata.base64String = this.sellIn.base64;
          pdata.fiscalYear = this.filters.yearSelected.value;
          pdata.quarter = this.filters.quarterSelected.value;
          this.$store
            .dispatch(DEALER_SELL_TRHU_UPLOAD_REQUEST, pdata)
            .then(res => {
              if (res.resultCode === 0) {
                this.dealerSalesInModalToggle();
                this.getSellThruList();
                this.$store.dispatch(NOTIFICATION_SUCCESS, {
                  message: "Dealer Sell Thru Added Successfully"
                });
              }
            });
        }
      });
    },
    validateFields(sheet){
      let valid = true;
      if(sheet[0]["Quantity"] < 0){
        valid = false;
        this.sellInErrors.file = "Qunatity field can't be negative";
      } else if(!sheet[0]["MDM Code"]){
        valid = false;
        this.sellInErrors.file = "MDM Code field can't be empty";
      } else if(!sheet[0]["MTM Number"]){
        valid = false;
        this.sellInErrors.file = "MTM Number field can't be empty";
      } else if(!sheet[0]["Dealer Name"]){
        valid = false;
        this.sellInErrors.file = "Dealer Name field can't be empty";
      } else if(sheet[0]["Quantity"] % 1 != 0){
        valid = false;
        this.sellInErrors.file = "Quantity field can't be decimal";
      } else if(!new RegExp("^[W][0-9]{1,2}$").test(sheet[0]["Week"])){
        valid = false;
        this.sellInErrors.file = "Week field must have format of 'W1'";
      } else if(Number(sheet[0]["Week"].replace( /^\D+/g, '')) > 13 || Number(sheet[0]["Week"].replace( /^\D+/g, '')) < 1){
        valid = false;
        this.sellInErrors.file = "Week field can't be greater than 13 and less than 1";
      }
      return valid;
    },
    handleFileUpload(event) {
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Validating file..."
      });

      const file = event.target.files[0];

      // Validating file upload extension
      if (this.checkUploadType(file.name)) {
        this.getBase64(file).then(data => {
          var workbook = XLSX.read(data, { type: "base64" });
          var first_sheet_name = workbook.SheetNames[0];

          // Get worksheet
          let worksheet = workbook.Sheets[first_sheet_name];
          const sheet = XLSX.utils.sheet_to_json(worksheet, { raw: true });

          //Validating file upload columns
          if (sheet.length > 0) {
            if (this.checkFileHeaders(Object.keys(sheet[0]))) {
              if(this.validateFields(sheet)){
                this.dataAdjustmentsAfterUpload(sheet, file.name);
                this.sellIn.base64 = data;
                this.$store.dispatch(NOTIFICATION_SUCCESS, {
                  message: "File validation completed successfully",
                  timeOut: true
                });
              } else {
                this.$store.dispatch(NOTIFICATION_ERROR, {
                message:
                  this.sellInErrors["file"]
              });
              }
            } else {
              this.$store.dispatch(NOTIFICATION_ERROR, {
                message:
                  "File format incorrect. Please use provided incentive list template"
              });
            }
          } else {
            this.$store.dispatch(NOTIFICATION_ERROR, {
              message: "No data in file!"
            });
          }
        });
      }
    },
  },
  mounted() {
    this.getFilters();
    this.getSellThruList();
  }
};
</script>

<style></style>
