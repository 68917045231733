<template>
  <div id="download-turn-on-duration">
    <breadcrumb
      title="Dashboard"
      subtitle="Download"
      sub-sub-title="Demo Display Duration"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row mb-3">
                <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                    ref="country"
                    v-model="filters.countrySelected"
                    :searchable="false"
                    :options="countryOptions"
                    :disabled="!isCapAdmin && !isCap"
                    label="name"
                    name="country"
                    track-by="id"
                    :loading="!countryOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </Multiselect>
                </div>

                <div class="col-lg-4 d-flex justify-content-end" style="flex-direction: column">
                  <div class="form-group flex-grow-1 mb-0 d-flex justify-content-end" style="flex-direction: column; margin-bottom: 0!important">
                    <label class="text-label">Date Range</label>
                    <DateRangePicker
                      ref="picker"
                      v-model="dateRange"
                      :date-range="dateRange"
                      :opens="datePicker.opens"
                      :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                      :min-date="datePicker.minDate"
                      :max-date="datePicker.maxDate"
                      :single-date-picker="datePicker.singleDatePicker"
                      :time-picker="datePicker.timePicker"
                      :time-picker24hour="datePicker.timePicker24Hour"
                      :show-week-numbers="datePicker.showWeekNumbers"
                      :show-dropdowns="datePicker.showDropdowns"
                      :auto-apply="datePicker.autoApply"
                      :linked-calendars="datePicker.linkedCalendars"
                      :date-format="datePicker.dateFormat"
                      :ranges="datePicker.ranges"
                      :on-click-date="handleClick"
                    >
                      <div
                        slot="input"
                        slot-scope="picker"
                        style="min-width: 310px; width: 100%; float: left;"
                      >
                        <span v-if="picker.startDate && picker.endDate">{{ formatDate(picker.startDate) }} to
                          {{ formatDate(picker.endDate) }}</span>
                      </div>
                    </DateRangePicker>
                  </div>
                </div>

                <div class="col-lg-3 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                  <div class="d-inline-flex">
                    <button class="btn btn-sm btn-primary mb-0" style="margin-top:24px;height:40px;width: 100%" @click="toggleModal">
                      <span>Send Email</span>
                    </button>
                    <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                      <span>Download</span>
                      <div 
                        v-if="isDataLoading" 
                        class="loading ml-3" 
                        style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                      >
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BModal ref="send-email-modal" size="lg" hide-footer title="Send Email">
        <div class="row">
          <div class="col-lg-12 d-inline-flex justify-content-between mt-5 mb-3">
            <div class="col-lg-8">
              <input 
                v-model="email"
                v-validate="'required'"
                type="text"
                name="Email"
                class="form-control"
              >
            </div>
            <div class="col-lg-4">
              <button class="btn btn-sm btn-primary mt-0 w-100" style="height:40px" :disabled="sendingEmail" @click="doSendEmail">
                <span>Send</span>
              </button>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-lg-12">
              <p class="invalid-field">{{ error }}</p>
            </div>
          </div>
        </div>
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { convertDateFormat } from "../../utils/helpers";
import DateRangePicker from "../../components/ui/vue2-daterange-picker/src";
import "../../components/ui/vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { DOWNLOAD_HEARTBEAT_REQUEST, SEND_HEARTBEAT_REQUEST } from "../../store/modules/download/actions";
import { BModal } from "bootstrap-vue";
import { 
  NOTIFICATION_REQUEST, 
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR 
} from "../../store/modules/ui/actions";
import cloneDeep from "lodash/cloneDeep";
import Multiselect from "vue-multiselect";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";

const today = new Date();
const CAP = { id:null, code:"CAP", name:"CAP" }

export default {
  name: "TurnOnDuration",
  components: {
    breadcrumb: breadcrumb,
    DateRangePicker: DateRangePicker,
    Multiselect: Multiselect,
    BModal: BModal,
  },
  data() {
    return {
      email: this.$store.getters.user.name,
      error: "",
      sendingEmail: false,
      dataList: [],
      countryOptions: [],
      dateOption:[],
      filters: {
        countrySelected: null,
      },
      dateRange: {
        startDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
          "YYYY-MM-DD"
        ),
        endDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6),
          "YYYY-MM-DD"
        )
      },
      datePicker: {
        opens: "center",
        singleDatePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        timePicker: false,
        autoApply: false,
        ranges: false,
        numberOfDaysToSelect: 30,
        maxDate: null,
      },
      isCapAdmin: this.$store.getters.isCapAdmin,
      isCap: this.$store.getters.isCap,
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isGLNCountryAdmin: this.$store.getters.isGLNCountryAdmin,
      countries: [],
      countryCode: this.$store.getters.getCountryCode,
      isCountriesLoading: false,
      isDataLoading: false,
      link: ""
    }
  },
  watch: {},
  methods: {
    emailValidate(value){
      let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return re.test(value);
    },
    async doSendEmail(){
      if(this.email === ""){
        return this.error = "Email can't be blank"
      } 
      if(!this.emailValidate(this.email)){
        return this.error = "Invalid email"
      }
      
      this.sendingEmail = true;
      let startDate = new Date(this.dateRange.startDate).getTime();
      let endDate = new Date(this.dateRange.endDate).getTime();
      let countryCode = this.filters.countrySelected.id;

      let pdata = {
        email: this.email,
        startDate: startDate,
        endDate: endDate,
        countryIdList: [countryCode],
      };

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: this.$ml.get("request_submitting")
      });
      this.$store.dispatch(SEND_HEARTBEAT_REQUEST, pdata)
        .then(res => {
          if(res.resultCode === 0){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Please check your email"
            });
            this.toggleModal();
          }
          this.sendingEmail = false;
        })
        .catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
          this.sendingEmail = false;
        });
      
    },
    toggleModal(){
      this.sendingEmail = false;
      this.email = this.$store.getters.user.name;
      this.error = "";
      this.$refs["send-email-modal"].toggle("#toggle-btn");
    },
    changeDateFormat(date, format) {
      return convertDateFormat(date, format);
    },
    doExport() {
      this.isDataLoading = true;
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Downloading..."
      });
      let startDate = new Date(this.dateRange.startDate).getTime();
      let endDate = new Date(this.dateRange.endDate).getTime();
      let countryCode = this.filters.countrySelected.id;
      
      let pData = {
        countryIdList: [countryCode],
        startDate: startDate,
        endDate: endDate,
      };
      
      this.$store.dispatch(DOWNLOAD_HEARTBEAT_REQUEST, pData).then(res => {
        if(res.resultCode === 0 && res.content){
          window.open(res.content.link);
        }
        this.isDataLoading = false;
      }).catch(err => {
        this.isDataLoading = false;
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: err
        });
      })
    },
    formatDate: function(date) {
      return convertDateFormat(String(date), "YYYY-MM-DD");
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, { withRegion: false })
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = res.content;
          this.countryOptions = cloneDeep(res.content)
          .filter(el => {
            return el.code != "KR" && el.code != "KH"
          })
          .map(el => {
            return {
              id: el.id,
              name: el.text,
              code: el.code
            }
          })
          if (this.isCap || this.isCapAdmin) {
            this.countryOptions.unshift(CAP)
            this.filters.countrySelected = CAP
          } else {
            this.filters.countrySelected = this.countryOptions.find(el => el.code === this.$store.getters.getCountryCode);
          }
        }
      });
    },
    handleClick(startDate, isSelection) {

      if (startDate && isSelection) {
        let startingDate = new Date(startDate)

        this.datePicker.maxDate = convertDateFormat(
          startingDate.setDate(startingDate.getDate() + this.datePicker.numberOfDaysToSelect),
          "YYYY-MM-DD"
        )
      } else {
        this.datePicker.maxDate = null
      }
    },
  },
  async mounted() {
    this.getCountries();
  },
}

</script>

<style lang="scss">
.salesTheaderNoWrap {
  .table > thead > tr > th {
    white-space: nowrap !important;
  }
}

.text-label.sales-data::after, .text-label.sales-data::before{
  margin: 1px .4rem .4rem .4rem;
}
</style>