<template>
  <div id="promo-leader-list">
    <breadcrumb
      title="Dashboard"
      subtitle="Staff Management"
      sub-sub-title="Promoter Supervisors"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row">
                <div
                  class="col-lg-12 add-employee-button d-flex align-items-center"
                >
                  <b-field class="mr-auto" grouped group-multiline>
                    <div
                      class="control clickable"
                      @click="filterList('normal')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large">
                          Registered
                        </b-tag>
                        <b-tag type="is-info" size="is-large">
                          <strong>{{ leaderList.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                    <div
                      class="control clickable"
                      @click="filterList('active')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large">Active</b-tag>
                        <b-tag type="is-success" size="is-large">
                          <strong>{{ leaderListActive.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                    <div
                      class="control clickable"
                      @click="filterList('deactivated')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large">
                          Deactivated
                        </b-tag>
                        <b-tag type="is-danger" size="is-large">
                          <strong>{{ leaderListDeactivated.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                  </b-field>
                  <button
                    v-if="canAdd"
                    class="btn btn-sm btn-primary"
                    @click="toggleModal(null)"
                  >
                    <i class="fa fa-plus"></i>
                    <span v-text="$ml.get('add_promoter_leader_title')"></span>
                  </button>
                  <button
                    v-if="canDownload"
                    :disabled="isDataLoading"
                    class="btn btn-sm btn-primary"
                    @click="doExport"
                  >
                    <i class="fa fa-download"></i>
                    <span v-text="$ml.get('export_to_excel')"></span>
                  </button>
                  <button
                    v-if="canUpload"
                    class="btn btn-sm btn-primary d-none"
                    @click="toggleUploadModal"
                  >
                    <i class="fa fa-upload"></i>
                    <span v-text="$ml.get('button_upload_text')"></span>
                  </button>
                  <br>
                  <br>
                  <br>
                </div>

                <div class="col-lg-4">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :disabled="!isCapAdmin && !isCap"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-4">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-3">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-1">
                  <button class="btn btn-sm btn-primary" style="margin-top:24px;height:40px;width: 100%" :disabled="isDataLoading" @click="getLeaderList()">
                    <span class="position-absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">Search</span>
                  </button>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                      :classes="classes"
                      :rows="filterState"
                      :columns="columns"
                      :config="config"
                      :total-rows="total"
                      @on-change-query="onChangeQuery"
                      @refresh-data="getLeaderList"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isDataLoading"
                        class="bold-text"
                        v-text="$ml.get('no_leaders_found')"
                      ></div>
                      <div
                        v-if="isDataLoading"
                        class="loading mt-3"
                      ></div>
                    </template>

                    <template slot="fullName" slot-scope="props">
                      <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 1)">
                        <span style="color:#F70201">
                          {{ props.row.fullName }}
                        </span>
                      </div>
                    </template>

                    <template slot="attainmentRate" slot-scope="props">
                      <div class="inner-data">
                        <span>{{ props.row.attainmentRate }}%</span>
                      </div>
                    </template>

                    <!-- <template slot="promoterCount" slot-scope="props">
                      <div class="inner-data" style="cursor: pointer" @click="loadPromoters(props.row)">
                        <span style="color:#F70201">
                          {{ props.row.promoterCount }}
                        </span>
                      </div>
                    </template> -->
                    <template slot="bEnabled" slot-scope="props">
                      <!-- <span v-if="!props.row.status">DEACTIVE</span>
                      <span v-if="props.row.status">ACTIVE</span>-->
                      <lnvStatusTextBox :active="props.row.bEnabled" />
                      <!-- <span
                        class="label label-rounded label-success"
                        v-if="props.row.status"
                        v-text="$ml.get('active_status_text')"
                      ></span>
                      <span
                        class="label label-rounded label-danger"
                        v-if="!props.row.status"
                        v-text="$ml.get('deactive_status_text')"
                      ></span>-->
                    </template>

                    <template slot="Action" slot-scope="props">
                      <div
                        class="inner-buttons btn-group float-right"
                        role="group"
                      >
                        <button
                          v-if="canEdit"
                          class="btn btn-warning btn-xs"
                          @click="toggleModal(props.row)"
                        >
                          <i class="fa fa-edit"></i>
                          <span v-text="$ml.get('button_edit_text')"></span>
                        </button>
                        <button
                          v-if="canEdit"
                          class="btn btn-danger btn-xs"
                          @click="doChangeStatus(props.row)"
                        >
                          <i class="fa fa-exchange"></i>
                          <span v-text="$ml.get('button_status_text')"></span>
                        </button>
                      </div>
                    </template>
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BModal ref="leader-modal" size="lg" hide-footer :title="setFormTitle">
      <leaderForm
        :can-edit="canEdit"
        :can-view="canView"
        :close-modal="toggleModal"
        :leader-selected="leader"
        :load-data="getLeaderList"
        :current-action="currentAction"
        :fiscal-year-filter="filters.yearSelected"
        :quarter-filter="filters.quarterSelected"
      />
    </BModal>

    <BModal
      ref="promoter-modal"
      size="lg"
      hide-footer
      title="Promoters"
    >
      <LeaderPromoters
        :close-modal="togglePromotersModal"
        :leader-selected="leader"
        :load-data="getLeaderList"
        :can-edit="canEdit"
        :can-view="canView"
        :current-action="currentAction"
      />
    </BModal>

    <BModal ref="upload-modal" size="xl" hide-footer title="Upload">
      <excelUploadForm
        :close-modal="toggleUploadModal"
        :validations="validateFields"
        :sample-file="sampleFile"
        :content="content"
        :set-post-data="setPostData"
        :show-data-list="true"
        :submit-button-text="'Submit'"
        :get-upload-file="getUploadFile"
        :file-columns="fileColumnStructure"
      />
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import leaderForm from "../../components/staff-management/promo_leader_form";
import { BModal } from "bootstrap-vue";
import { Message } from "element-ui";
import default_image from "../../assets/default_image.jpg";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import {
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST,
  POST_STAFF_REQUEST
} from "../../store/modules/staff/actions";
import excelUploadForm from "../../components/excel_upload/index";
import { DASHBOARD_TOP_FILTERS_GET } from "../../store/modules/dashboard/actions";
import { export_json_to_excel } from "../../utils/Export2Excel";
import LeaderPromoters from "../../components/staff-management/leader_promoters";

import {
  phoneValidate,
  emailValidate,
  nameValidate,
  defaultFiscalYear,
  defaultQuarter
} from "../../utils/helpers";
import { COUNTRY_LIST_REQUEST } from "../../store/modules/utility/actions";
import Multiselect from "vue-multiselect";
import "viewerjs/dist/viewer.css";
import lnvStatusTextBox from "../../components/ui/statusTextBox";
import { convertDateFormat } from "../../utils/helpers";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import {GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG} from "../../store/modules/staff/actions";

export default {
  name: "PromoterSupervisors",
  components: {
    breadcrumb: breadcrumb,
    leaderForm: leaderForm,
    BModal: BModal,
    VueBootstrap4Table: VueBootstrap4Table,
    excelUploadForm: excelUploadForm,
    LeaderPromoters: LeaderPromoters,
    Multiselect: Multiselect,
    lnvStatusTextBox: lnvStatusTextBox
  },
  computed: {
    filterState() {
      if (this.normal) {
        return this.leaderList;
      } else if (this.activeList) {
        return this.leaderListActive;
      } else if (this.deactivatedList) {
        return this.leaderListDeactivated;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      defaultImage: default_image,
      sampleFile: "promoterSupervisorUpload.xlsx",
      content:
        "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the column labels in the sample file and make sure the file extension is .xlsx file.",
      excel_fields: {
        No: "id",
        Staff_name: "name",
        Email: "productGroupId",
        Contact_number: "productSubGroupId"
      },
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Supervisor",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false,
          init: {
            value : ""
          }
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "countryCode",
          label: "CTRY",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "accId",
          label: "User ID",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "fullName",
          label: "Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "promoterCount",
          label: "# Promoters",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "target",
          label: "Target",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "actual",
          label: "Actual",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "attainmentRate",
          label: "Attainment",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }, {
          name: "bEnabled",
          label: "Promoter Supervisor Status",
          width: "10%",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          initial_sort: true,
          initial_sort_order: "desc",
        },
        {
          name: "Action",
          label: "Action",
          width: "10%",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }
      ],
      fileColumnStructure: ["Name", "Email", "Phone Number"],
      total: 0,
      leaderList: [],
      leaderListActive: [],
      leaderListDeactivated: [],
      leader: {},
      postLeaderData: [],
      isCapAdmin: false,
      isCap: false,
      isCountryView: false,
      countries: [],
      search: {},
      countryId: null,
      canAdd: false,
      canUpload: false,
      canDownload: false,
      canDelete: false,
      canEdit: false,
      canView: false,
      setFormTitle: "Add Promoter Supervisor",
      currentAction: false,
      isCountriesLoading: false,
      isDataLoading: false,
      normal: true,
      activeList: false,
      deactivatedList: false,
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      filters: {
        countrySelected: {},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      }
    };
  },
  methods: {
    showModal() {
      this.leader = {};
      this.$refs["leader-modal"].show();
    },
    hideModal() {
      this.leader = {};
      this.$refs["leader-modal"].hide();
    },
    toggleModal(row, view) {
      if (row) {
        if (view) {
          this.setFormTitle = "View Promoter Supervisor";
          this.currentAction = "view";
        } else {
          this.setFormTitle = "Edit Promoter Supervisor";
          this.currentAction = "edit";
        }
        this.leader = row;
      } else {
        this.setFormTitle = "Add Promoter Supervisor";
        this.currentAction = "edit";
        this.leader = {};
      }

      this.$refs["leader-modal"].toggle("#toggle-btn");
    },
    loadPromoters(row) {
      this.leader = row;
      this.togglePromotersModal();
    },
    togglePromotersModal() {
      this.$refs["promoter-modal"].toggle("#toggle-btn3");
    },
    toggleUploadModal() {
      this.$refs["upload-modal"].toggle("#toggle-btn2");
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getLeaderList();
    },
    onChangeCountry(value) {
      if (value) {
        this.countryId = value.id;
        // this.getLeaderList();
      }
    },
    doSearch() {
      this.countryId = this.search.countryId ? this.search.countryId.id : null;
      this.getLeaderList();
    },
    getLeaderList() {
      let pdata = {
        ccf: this.filters.countrySelected.value || this.$store.getters.getCountryCode,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value
      };
      this.isDataLoading = true;
      this.leaderList = [];
      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      if (this.countryId) {
        pdata.countryId = this.countryId;
      }

      this.$store.dispatch(GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.leaderList = res.content;
          this.total = this.leaderList.length;
          this.getStats();
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
      });
    },

    replaceImageByDefault: function(e) {
      e.target.src = default_image;
    },

    doEdit(row) {
      this.leader = row;
      this.$refs["leader-modal"].toggle("#toggle-btn");
    },

    async doChangeStatus(row) {
      let status;
      await this.$store.dispatch(GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG, row.accId).then(res => {
        if (res.resultCode === 0) {
          status = res.content;
        }
      });
      if((this.isCountryAdmin || this.isGLNCountryAdmin) && status){
        this.$buefy.dialog.alert({
          title: 'Pending for approval',
          message: 'Sorry, this promoter leader is pending for approval and can\'t be changed now.',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$buefy.dialog.confirm({
          message: row.bEnabled
            ? "Do you want to <b class='red-heighlighted'>deactivate</b> " +
              row.fullName
            : "Do you want to <b class='red-heighlighted'>activate</b> " +
              row.fullName,
          onConfirm: () => {
            let pdata = {};
            pdata.data = {};
            pdata.staffId = row.accId;
            pdata.data.id = row.accId;
            pdata.data.status = !row.bEnabled;
            pdata.type = "leaders";

            this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
              if (res.resultCode === 0) {
                this.getLeaderList();
                this.$store.dispatch(NOTIFICATION_SUCCESS, {
                  message: row.bEnabled
                    ? "Supervisor Deactivated."
                    : "Supervisor Activated.",
                  timeOut: true
                });
              }
            });
          }
        });
      }
    },
    doDelete(row) {
      this.$buefy.dialog.confirm({
        message: "Do you want to delete " + row.name,
        onConfirm: () => {
          //  this.$buefy.toast.open("User confirmed")
        }
      });
    },
    doExport() {
      const tHeader = [
        "Country",
        "User ID",
        "Name",
        "Number of Promoters",
        "Target",
        "Actual",
        "Attainment Rate %",
        "Email",
        "Phone Number",
        "Status"];
      const filterVal = [
        "countryCode",
        "accId",
        "fullName",
        "promoterCount",
        "target",
        "actual",
        "attainmentRate",
        "username",
        "phoneNumber",
        "bEnabled"];
      const data = this.formatJson(filterVal, this.leaderList);
      const fileName = "promoterLeader";
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "promoterSupervisors",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "invoiceDate") {
            return convertDateFormat(v[j], "YYYY-MM-DD");
          } else if (j === "bEnabled") {
            return v[j] ? "Active" : "Deactive";
          } else {
            return v[j];
          }
        })
      );
    },
    validateFields(value, field) {
      //   return true;
      let isValid = false;
      switch (field) {
        case "Name":
          isValid = nameValidate(value);
          break;
        case "Email":
          isValid = emailValidate(value);
          break;
        case "Phone Number":
          isValid = phoneValidate(value);
          break;
        default:
          isValid = true;
          break;
      }

      if (!isValid) {
        value = '<span class="invalid-field">' + value + "</span>";
      }

      return {
        value: value,
        valid: isValid
      };
    },
    setPostData(pdata) {
      let tdata = [];

      pdata.forEach(function(row) {
        tdata.push({
          name: row[0],
          email: row[1],
          phoneNumber: row[2]
        });
      });

      this.postLeaderData = tdata;
      if (this.postLeaderData.length > 0) {
        this.submitPromoLeaderData();
      }
    },
    submitPromoLeaderData() {
      let pdata = {};
      pdata.type = "leaders";
      pdata.data = this.postLeaderData;
      this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.getLeaderList();
          this.toggleUploadModal();
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Promoter Supervisors Successfully Added",
            timeOut: true
          });
        }
      });
    },
    getUploadFile() {},
    getCountries() {
      this.isCountriesLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          this.isCountriesLoading = false;
        } else {
          this.isCountriesLoading = false;
        }
      });
    },
    getStats() {
      this.leaderListActive = [];
      this.leaderListDeactivated = [];

      this.leaderListActive = this.leaderList.filter(list => {
        if (list.bEnabled) {
          return list;
        }
      });
      this.leaderListDeactivated = this.leaderList.filter(list => {
        if (!list.bEnabled) {
          return list;
        }
      });
    },
    filterList(list) {
      if (list == "normal") {
        this.normal = true;
        this.activeList = false;
        this.deactivatedList = false;
      } else if (list == "active") {
        this.normal = false;
        this.activeList = true;
        this.deactivatedList = false;
      } else if (list == "deactivated") {
        this.normal = false;
        this.activeList = false;
        this.deactivatedList = true;
      }
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
        this.filters.countrySelected = this.isCap || this.isCapAdmin ? this.$store.getters.topFilterCountryData : 
          this.countryOptions.find(el => el.value === this.$store.getters.getCountryCode);
      }
    },
  },
  mounted() {
    if(this.$route.params.fullname !== undefined) {
      this.config.global_search.init.value = this.$route.params.fullname;
      this.onChangeQuery();
    }
    this.getFilters();
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isCountryView = this.$store.getters.isCountryView;

    if (this.isCap || this.isCountryAdmin || this.isGLNCountryAdmin) {
      this.canAdd = true;
      this.canUpload = true;
      this.canDownload = true;
      this.canEdit = true;
      this.canDelete = false;
      this.canView = true;
    } else if (this.isCapAdmin || this.isCountryView) {
      this.canAdd = false;
      this.canUpload = false;
      this.canDownload = true;
      this.canEdit = false;
      this.canDelete = false;
      this.canView = true;
    } else {
      this.canAdd = true;
      this.canUpload = true;
      this.canDownload = true;
      this.canEdit = false;
      this.canDelete = true;
      this.canView = false;
    }
  },
  created() {
  }
};
</script>
