import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";
import {
  API_INCENTIVE_NORMAL_LIST_SERVICE_NEW_SALESPERSON,
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM,
  API_INCENTIVE_DETAILS_BY_ID,
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM,
  API_INCENTIVE_NEW_SALESPERSON_FILE_CHECK,
  API_INCENTIVE_NEW_SALESPERSON_TEMPLATE
} from "../constants/services"; 

export function getIncentiveNormalListSalesperson() {
  return request({
    url: API_INCENTIVE_NORMAL_LIST_SERVICE_NEW_SALESPERSON ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function addIncentiveNormalListSalesperson(postData) {
  return request({
    url: ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function getIncentiveDetailsById(postData) {
  return request({
    url: API_INCENTIVE_DETAILS_BY_ID + "/" + postData,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function updateIncentiveNormalListSalesperson(postData) {
  return request({
    url: UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function fileCheckIncentiveNormalListSalesperson(postData) {
  return request ({
    url: API_INCENTIVE_NEW_SALESPERSON_FILE_CHECK,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT,
  });
}

export function getIncentiveNormalListSalespersonTemplate() {
  return request ({
    url: API_INCENTIVE_NEW_SALESPERSON_TEMPLATE,
    method: "get",
    timeout: DEFAULT_TIMEOUT,
  });
}
