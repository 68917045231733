<template>
  <div>
  <div v-if="!isRendering" id="dealer-form">
    <p
      v-if="canEdit && currentAction === 'edit' && dealer.pendingApprovalStatus && !selectedDealer.newApprovalRequest"
      class="required-title"
      v-text="$ml.get('dealer_pending_approval_request')"
    ></p>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="canEdit && currentAction === 'edit'" @submit.prevent="handleSubmit">
      <div class="row">
        <div v-if="dealer.id > 0 && !isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_reg_date')"></label>
            <input
              v-model="dealer.joinedDate"
              :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
              readonly
              type="text"
              class="form-control"
              placeholder
            />
          </div>
        </div>
        <div v-if="(dealer.id > 0 && !selectedDealer.newApprovalRequest && !isChangeStatus)" class="col-lg-6">
          <label class="text-label" v-text="$ml.get('form_news_status')"></label><br>
          <div class="d-inline-flex pt-3">
            <div class="row d-flex m-0 p-0">
              <td class="mr-4 none-border col"><lnvStatusTextBox :status="dealer.status" /></td>
              <button
                v-if="!(isCountryView || isPendingApprovalPage)"
                class="btn btn-outline-dark m-auto col"
                @click="doChangeStatus($event)"
              >
              <i class="fa fa-exchange"></i>
              </button> 
            </div>
            <label v-if="dealer.activationDate !== null || dealer.deactivationDate !== null" class="text-label ml-4 mt-2">
              Since: {{ dealer.outletStatus == 'ACTIVE' ? dealer.activationDate : dealer.deactivationDate }}
            </label>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6" style="display:none;">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_store_id')"></label>
            <input
              v-model="dealer.storeId"
              :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.storeId = ''"
              @change="formErrors.storeId = ''"
            />
            <span v-if="formErrors.storeId" class="invalid-field">{{ formErrors.storeId }}</span>
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-12">
          <hr />
          <h4
            class="text-primary"
            style="line-height: 1rem; font-size: 1.4rem;"
            v-text="$ml.get('form_dealer_dealer_info')"
          ></h4>
          <br />
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('dealer_name')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex p-0 m-0">
              <textarea
                id="dealerName"
                v-model="dealer.dealerName"
                :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
                name="dealerName"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.dealerName = ''"
              />
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.dealerName" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                id="dealerNameAfter"
                v-model="dealerAfterChanges.dealerName"
                disabled
                type="text"
                class="form-control after-change"
              />
              </div>
            </div>
            <span v-if="formErrors.dealerName" class="invalid-field">{{
              formErrors.dealerName
            }}</span>
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_accreditation')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <Multiselect
                ref="accreditation"
                v-model="dealer.accreditationId"
                class="col"
                :disabled="selectedDealer.newApprovalRequest || !(!isCountryView || !(dealer.id > 0)) || isPendingApprovalPage"
                :searchable="false"
                :options="accreditationList"
                label="text"
                track-by="id"
                :loading="!Array.isArray(accreditationList) || !accreditationList.length"
                :allow-empty="false"
                :show-labels="false"
                @input="formErrors.accreditationId = ''"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.accreditationId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="accreditation-after"
                  v-model="dealerAfterChanges.accreditationId"
                  class="after-change"
                  disabled
                  :options="accreditationList"
                  label="text"
                  track-by="id"
                  :loading="!Array.isArray(accreditationList) || !accreditationList.length"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.accreditationId" class="invalid-field">{{
              formErrors.accreditationId
            }}</span>
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_mdm_code')"></label>
            <span class="required-star">*</span>
            <input
              v-model="dealer.prmLppName"
              :disabled="dealer.id > 0"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.prmLppName = ''"
            />
            <span v-if="formErrors.prmLppName" class="invalid-field">{{
              formErrors.prmLppName
            }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_account_manager')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <textarea
                v-model="dealer.accountManager"
                :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.accountManager = ''"
              />
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.accountManager" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                v-model="dealerAfterChanges.accountManager"
                disabled
                type="text"
                class="form-control after-change"
              />
              </div>
            </div>
            <span v-if="formErrors.accountManager" class="invalid-field">{{
              formErrors.accountManager
            }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="country"
              v-model="dealer.countryCode"
              :searchable="false"
              :options="countryOptions"
              :disabled="isCountryView || isPendingApprovalPage || selectedDealer.newApprovalRequest && true"
              label="name"
              track-by="value"
              :loading="!Array.isArray(countryOptions) || !countryOptions.length"
              :allow-empty="false"
              :show-labels="false"
              @input="onChangeCountry"
            ></Multiselect>
            <span v-if="errors.first('Country')" class="invalid-field">
              {{ errors.first("Country") }}
            </span>
            <span v-if="formErrors.countryCode" class="invalid-field">{{ formErrors.countryCode }}</span>
          </div>
        </div>

        <!-- Hide Allow Bulk Upload for now -->
        <div v-if="!isChangeStatus && false" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_allow_bulk_upload')"></label>
            <div class="form-group mb-0">
              <div class="row d-flex m-0 p-0">
                <label class="switch col-2" for="bulkUpload">
                  <input id="bulkUpload" v-model="dealer.bulkUpload" :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage" type="checkbox" />
                  <div class="slider round"></div>
                </label>
                <!-- Change Request -->
                <div v-if="dealerAfterChanges.bulkUpload" class="col d-inline-flex m-0 p-0">
                  <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                  <label class="switch after-change" for="bulkUpload">
                    <input id="bulkUpload" v-model="dealerAfterChanges.bulkUpload" disabled type="checkbox" />
                    <div class="slider round"></div>
                  </label>
                </div>
              </div>
              <span v-if="formErrors.bulkUpload" class="invalid-field">{{
                formErrors.bulkUpload
              }}</span>
            </div>
          </div>
        </div>

        <!-- <div v-if="dealer.id > 0" class="col-12">
          <div class="row">
            <div class="col-4">
              <button class="btn btn-info btn-block" type="button" @click="togglePICModal">
                <i class="fa fa-user-o"></i>
                <span v-text="$ml.get('form_dealer_outlet_pic')"></span>
                ({{ picListCount }})
              </button>
            </div>
            <div class="col-4">
              <button class="btn btn-info btn-block" type="button" @click="toggleOutletModal">
                <i class="fa fa-building-o"></i>
                <span v-text="$ml.get('form_dealer_outlet_list')"></span>
                ({{ outletListCount }})
              </button>
            </div>
            <div class="col-4">
              <button class="btn btn-info btn-block" type="button" @click="toggleSalesPModal">
                <i class="fa fa-user-o"></i>
                <span v-text="$ml.get('form_dealer_salesperson')"></span>
                ({{ dealer.salesPersonListCount }})
              </button>
            </div>
          </div>
        </div> -->

        <div v-if="!isChangeStatus" class="col-12">
          <hr />
          <h4
            class="text-primary"
            style="line-height: 1rem;    font-size: 1.4rem;"
            v-text="$ml.get('form_dealer_owner_info')"
          ></h4>

          <br />
        </div>

        <div v-if="!isChangeStatus" class="col-lg-12">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_owner_name')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <textarea
                id="ownerName"
                v-model="dealer.ownerName"
                :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
                name="ownerName"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.ownerName = ''"
              />
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.ownerName" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                id="ownerNameAfter"
                v-model="dealerAfterChanges.ownerName"
                disabled
                type="text"
                class="form-control after-change"
                />
              </div>
            </div>
            <span v-if="formErrors.ownerName" class="invalid-field">{{
              formErrors.ownerName
            }}</span>
          </div>
        </div>

        <!-- <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_owner_ic')"></label>
            <span class="required-star">*</span>
            <input
              v-model="dealer.ownerIcOrId"
              :disabled="selectedDealer.newApprovalRequest"
              v-validate="'alpha_num'"
              type="text"
              class="form-control"
              :name="$ml.get('form_dealer_owner_ic')"
              placeholder
              @keyup="formErrors.ownerIcOrId = ''"
            />
            <span v-if="errors.first($ml.get('form_dealer_owner_ic'))" class="invalid-field">{{
              errors.first($ml.get("form_dealer_owner_ic"))
            }}</span>
            <span v-if="formErrors.ownerIcOrId" class="invalid-field">{{
              formErrors.ownerIcOrId
            }}</span>
          </div>
        </div> -->
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_contact_number')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <textarea
                v-model="dealer.contactNum"
                :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
                name="form_dealer_phone"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.contactNum = ''"
              />
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.contactNum" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                v-model="dealerAfterChanges.contactNum"
                disabled
                type="text"
                class="form-control after-change"
                />
              </div>
            </div>
            <span v-if="formErrors.contactNum" class="invalid-field">{{
              formErrors.contactNum
            }}</span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="errors.firstByRule('form_dealer_phone', 'regex') && !formErrors.contactNum"
              class="invalid-field"
              v-html="$ml.get('invalid_phone_number')"
            ></span>
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_email')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <textarea
                v-model="dealer.email"
                v-validate="{ regex: getEmailRegex() }"
                :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
                name="form_dealer_email"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.email = ''"
              />
              <!-- Change Request -->
              <div v-if="dealerAfterChanges.email" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                  v-model="dealerAfterChanges.email"
                  disabled
                  type="text"
                  class="form-control after-change"
                />
              </div>
            </div>
            <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
            <span
              v-if="errors.firstByRule('form_dealer_email', 'regex') && !formErrors.email"
              class="invalid-field"
              v-html="$ml.get('invalid_email_address')"
            ></span>
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="isChangeStatus" class="col-lg-12 mb-4" v-html="changeStatusMessage">
        </div>
        <!--eslint-enable-->
        <div v-if="selectedDealer.newApprovalRequest || isPendingApprovalPage || (!isCapAdmin && !isCap)" class="col-lg-12">
          <hr />
          <div class="form-group flex-grow-1">
            <label class="text-label">Reason</label>
            <span class="required-star">*</span>
            <input
              v-model="dealer.reason"
              :disabled="selectedDealer.newApprovalRequest || isPendingApprovalPage"
              type="text"
              class="form-control"
              placeholder="Kindly insert reason to add/update dealer here"
              @keyup="formErrors.reason = ''"
            />
            <span v-if="formErrors.reason" class="invalid-field">{{ formErrors.reason }}</span>
          </div>
        </div>
        
      </div>

      <!-- <h4 class="text-primary">Bank Information</h4> -->
      <!-- <div class="row">
        <div class="col-12">
          <hr />
          <h4
            class="text-primary"
            style="line-height: 1rem;    font-size: 1.4rem;"
            v-text="$ml.get('form_dealer_bank_info')"
          ></h4>
          <br />
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_holder_name')"></label>
            <span v-if="dealer.bulkUpload" class="required-star">*</span>
            <input
              v-model="dealer.bankAccNam"
              :disabled="selectedDealer.newApprovalRequest"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.bankAccNam = ''"
            />
            <span v-if="formErrors.bankAccNam" class="invalid-field">{{
              formErrors.bankAccNam
            }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
            <span v-if="dealer.bulkUpload" class="required-star">*</span> -->
            <!-- <select v-model="dealer.bankId" id="bankId" class="form-control">
              :disabled="selectedDealer.newApprovalRequest"
              <option disabled selected value>Please Select</option>
              <option v-for="bank of banks" :value="bank.id" :key="bank.id">{{bank.text}}</option>
            </select>-->
            <!-- <Multiselect
              ref="multiselect"
              v-model="dealer.bankId"
              :disabled="selectedDealer.newApprovalRequest"
              placeholder="Select Bank"
              :searchable="true"
              :options="banks"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="getBanksIsLoading"
            ></Multiselect>

            <span v-if="formErrors.bankId" class="invalid-field">{{ formErrors.bankId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_acc_number')"></label>
            <span v-if="dealer.bulkUpload" class="required-star">*</span>
            <input
              v-model="dealer.bankAccNum"
              :disabled="selectedDealer.newApprovalRequest"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.bankAccNum = ''"
            />
            <span v-if="formErrors.bankAccNum" class="invalid-field">{{
              formErrors.bankAccNum
            }}</span>
          </div>
        </div>
      </div> -->

      <div v-if="!(selectedDealer.newApprovalRequest || isPendingApprovalPage )" class="row">
        <div v-if="userRole !== 'CAP' && (!isCountryView || !(dealer.id > 0))" class="col-lg-12">
          <button type="submit" :disabled="dealer.id > 0 && dealer.pendingApprovalStatus || (false)" class="btn btn-secondary pull-right">
            <span v-if="!dealer.id" v-text="$ml.get('form_dealer_add_dealer')"></span>
            <span v-if="dealer.id > 0" v-text="$ml.get('form_dealer_update_dealer')"></span>
          </button>
          <button
            type="button"
            class="btn btn-light pull-right"
            data-dismiss="modal"
            @click="close()"
            v-text="$ml.get('button_cancel_text')"
          ></button>
        </div>
      </div>
    </form>

    <!-- View -->
    <div v-if="canView && currentAction === 'view'">
      <table class="table">
        <tbody>
          <tr>
            <td>Registered Date</td>
            <td>{{ dealer.joinedDate }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_store_id')"></td>
            <td>{{ dealer.storeId }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_lpp_code')"></td>
            <td>{{ dealer.prmLppName }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_account_manager')"></td>
            <td>{{ dealer.accountManager }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <h4
                class="text-primary mt-3"
                style="line-height: 1rem; font-size: 1.4rem;"
                v-text="$ml.get('form_dealer_dealer_info')"
              ></h4>
            </td>
          </tr>
          <tr>
            <td v-text="$ml.get('dealer_name')"></td>
            <td>{{ dealer.dealerName }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_accreditation')"></td>
            <td>{{ dealer.accreditationId.text }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_allow_bulk_upload')"></td>
            <td>
              <span v-if="dealer.bulkUpload">Yes</span>
              <span v-if="!dealer.bulkUpload">No</span>
            </td>
          </tr>
          <!-- <tr>
            <td colspan="2">
              <div class="row">
                <div class="col-4">
                  <button class="btn btn-info btn-block" type="button" @click="togglePICModal">
                    <i class="fa fa-user-o"></i>
                    &nbsp; PIC Outlet List ({{ picListCount }})
                  </button>
                </div>
                <div class="col-4">
                  <button class="btn btn-info btn-block" type="button" @click="toggleOutletModal">
                    <i class="fa fa-building-o"></i>
                    &nbsp; Outlet List ({{ outletListCount }})
                  </button>
                </div>
                <div class="col-4">
                  <button class="btn btn-info btn-block" type="button" @click="toggleSalesPModal">
                    <i class="fa fa-user-o"></i>
                    &nbsp; Salesperson ({{ dealer.salesPersonListCount }})
                  </button>
                </div>
              </div>
            </td>
          </tr> -->
          <tr>
            <td colspan="2">
              <h4
                class="text-primary mt-3"
                style="line-height: 1rem; font-size: 1.4rem;"
                v-text="$ml.get('form_dealer_owner_info')"
              ></h4>
            </td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_owner_name')"></td>
            <td>{{ dealer.ownerName }}</td>
          </tr>
          <!-- <tr>
            <td v-text="$ml.get('form_dealer_owner_ic')"></td>
            <td>{{ dealer.ownerIcOrId }}</td>
          </tr> -->
          <tr>
            <td v-text="$ml.get('form_dealer_contact_number')"></td>
            <td>{{ dealer.contactNum }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_email')"></td>
            <td>{{ dealer.email }}</td>
          </tr>
          <!-- <tr>
            <td colspan="2">
              <h4
                class="text-primary"
                style="line-height: 1rem; font-size: 1.4rem;"
                v-text="$ml.get('form_dealer_bank_info')"
              ></h4>
            </td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_bank_holder_name')"></td>
            <td>{{ dealer.bankAccNam }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_bank_name')"></td>
            <td>{{ dealer.bankId.text }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_dealer_bank_acc_number')"></td>
            <td>{{ dealer.bankAccNum }}</td>
          </tr> -->
        </tbody>
      </table>
    </div>

    <!-- Modal Windows -->
    <BModal ref="dealer-pic-modal" size="lg" hide-footer title="PIC">
      <DealerPicList :pic-list="dealer.picList" />
    </BModal>

    <BModal ref="dealer-outlet-modal" size="lg" hide-footer title="Outlets">
      <DealerOutletList :outlet-list="dealer.outletList" />
    </BModal>

    <BModal ref="dealer-salesp-modal" size="lg" hide-footer title="Salesperson">
      <DealerSalesPList :salesp-list="dealer.salespersonList" />
    </BModal>
  </div>
  <div v-else style="height: 450px"><div class="loading loading-big"></div></div>
  </div>
</template>

<script>
import DealerPicList from "./dealer_pic_outlets";
import DealerOutletList from "./dealer_outlets";
import DealerSalesPList from "./dealer_salespersons";
import { DEALER_POST_REQUEST, DEALER_REQUEST, DEALER_CHANGES_REQUEST } from "../../store/modules/dealer/actions";
import { GET_DEALER_PENDING_APPROVAL_FLAG } from "../../store/modules/staff/actions";
import { BANK_LIST_REQUEST, COUNTRY_LIST_REQUEST, ACCREDITATION_LIST_REQUEST } from "../../store/modules/utility/actions";
import { BModal } from "bootstrap-vue";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { nameValidate, emailValidate, phoneValidate, convertDateFormat } from "../../utils/helpers";
import Multiselect from "vue-multiselect";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import lnvStatusTextBox from "../../components/ui/statusTextBox";
import {Message} from "element-ui";
import cloneDeep from "lodash/cloneDeep"


export default {
  components: {
    DealerPicList: DealerPicList,
    DealerOutletList: DealerOutletList,
    DealerSalesPList: DealerSalesPList,
    BModal: BModal,
    Multiselect: Multiselect,
    lnvStatusTextBox: lnvStatusTextBox
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    selectedDealer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    canView: { type: Boolean },
    canEdit: { type: Boolean },
    currentAction: { type: String, default: "" }
  },
  computed: {
    outletListCount() {
      if (this.dealer.outletList) {
        return this.dealer.outletList.length;
      } else {
        return 0;
      }
    },
    picListCount() {
      if (this.dealer.picList) {
        return this.dealer.picList.length;
      } else {
        return 0;
      }
    },
    salespListCount() {
      if (this.dealer.salespList) {
        return this.dealer.salespersonList.length;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      isRendering: false,
      isPendingApprovalPage: this.$router.currentRoute.path == '/dashboard/change-requests-approval',
      dealerAfterChanges: {},
      isChangeStatus: false,
      allowToChangeStatus: false,
      changeStatusMessage: '',
      dealer: {
        joinedDate: this.changeDate(this.selectedDealer.joinedDate),
        dealerName: this.selectedDealer.dealerName,
        storeId: this.selectedDealer.storeId,
        prmLppName: this.selectedDealer.prmLppName,
        email: this.selectedDealer.email,
        ownerName: this.selectedDealer.ownerName,
        ownerIcOrId: this.selectedDealer.ownerIcOrId,
        contactNum: this.selectedDealer.contactNum,
        bulkUpload: this.selectedDealer.bulkUpload,
        accreditationId: this.selectedDealer.accreditation,
        accountManager: this.selectedDealer.accountManager,
        bankId: this.selectedDealer.bankId ? this.selectedDealer.bankId : "",
        bankAccNum: this.selectedDealer.bankAccNum,
        bankAccNam: this.selectedDealer.bankAccHolderName,
        id: this.selectedDealer.id,
        firstApprovalId: this.selectedDealer.firstApprovalId,
        status: this.selectedDealer.status,
        countryCode: null,
        outletList: [],
        picList: [],
        salespersonList: [],
        countries: [],
        isCountriesLoading: false,
        countryId: 0,
      },
      dealerBeforeChange: {},
      countryOptions: [],
      countries: [],
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      userRole: "",
      isCap: false,
      isCapAdmin: false,
      isCountryTrainer: false,
      isCountryView: false,
      banks: [],
      submitted: false,
      formErrors: [],
      getBanksIsLoading: false,
      isAccreditationLoading: false,
      accreditationList: []
    };
  },
  methods: {
    doChangeStatus(event) {
      if (event) event.preventDefault();
      if (!this.isChangeStatus) {
        this.isChangeStatus = true
        let status = this.selectedDealer.status;
        if ((
        // (this.isCountryAdmin || this.isGLNCountryAdmin) && 
        this.dealer.id 
        && this.dealer.pendingApprovalStatus)){
          this.changeStatusMessage = 'Sorry, this dealer is pending for approval and can\'t be changed now.'
          this.allowToChangeStatus = false
        } else {
          this.changeStatusMessage = status == "ACTIVE"
              ? "Do you want to <b class='red-heighlighted'>deactivate</b> " +
                this.selectedDealer.dealerName
              : "Do you want to <b class='red-heighlighted'>activate</b> " +
                this.selectedDealer.dealerName
          this.allowToChangeStatus = true
        }
        return
      }
      if (this.allowToChangeStatus === false) return
        this.selectedDealer.change_status = false;
        this.selectedDealer.change_status = true;
        this.selectedDealer.statusUpdateReason = this.dealer.reason

        // let status =  this.selectedDealer.status : this.selectedDealer.dealerStatus;
        this.$store.dispatch(DEALER_POST_REQUEST, this.selectedDealer).then(() => {
          this.loadData();
          Message({
            message:
                status == "ACTIVE"
                  ? "Dealer Deactivated."
                  : "Dealer Activated.",
            type: "success",
            duration: 5 * 1000
          });
          this.close();
        });
    },
    getPhoneRegex() {
      return phoneValidate(this.dealer.contactNum, 1);
    },
    getEmailRegex() {
      return emailValidate(this.dealer.email, 1);
    },
    togglePICModal() {
      this.$refs["dealer-pic-modal"].toggle("#toggle-btn");
    },
    toggleOutletModal() {
      this.$refs["dealer-outlet-modal"].toggle("#toggle-btn");
    },
    toggleSalesPModal() {
      this.$refs["dealer-salesp-modal"].toggle("#toggle-btn");
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    async getCountries() {
      this.countries = [];
      this.isDataLoading = true;
      await this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countries.push(country);
          });
          if(this.selectedDealer.countryName){
            const country = res.content.filter(ctr => ctr.text === this.selectedDealer.countryName)[0];
            this.dealer.countryCode = {name: country.text, value: country.code};
          }
          if (this.dealer.countryId) {
            this.dealer.countryId = this.getSelectedCountry(this.dealer.countryId);
          }
        }
        this.isDataLoading = false;
      });
    },
    getDealerChanges() {
      const isPending = this.dealer.status === "Pending"
      const postData = {
        id: isPending ? 0 : this.dealer.firstApprovalId,
        dealerId: isPending ? this.dealer.id : 0
      }

      this.$store.dispatch(DEALER_CHANGES_REQUEST, postData).then(res => {
        if (res.content && res.resultCode === 0) {
          this.dealerAfterChanges = res.content
          this.dealer.reason = this.dealerAfterChanges.reason
        }
      })
    },
    getSelectedCountry(id) {
      let c = this.countries.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getAccreditation(accreditation) {
      let c = this.accreditationList.filter(function(elem) {
        if (typeof accreditation == 'number') return elem.id == accreditation
        if (elem.text === accreditation) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return null;
      }
    },
    onChangeCountry() {
      this.countries.filter(country => {
        if(country.code === this.dealer.countryCode.value) {
          this.dealer.countryId = country.id;
        }
      });
      // this.outlet.dealerId = "";
      // this.outlet.stateId = "";
      // this.outlet.outletPicId = "";
      // this.getDealers();
      // this.getStates();
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;
      let haveChanges = false;

      if (!this.dealer.dealerName) {
        this.formErrors.dealerName = this.$ml.get("form_dealer_name_required");
        error++;
      } else {
        if (!nameValidate(this.dealer.dealerName)) {
          this.formErrors.dealerName = this.$ml.get("form_dealer_name_invalid");
          error++;
        }
        if (this.dealerBeforeChange.dealerName != this.dealer.dealerName) {
          haveChanges = true
        }
      }

      if (!this.dealer.prmLppName) {
        this.formErrors.prmLppName = this.$ml.get("form_dealer_lpp_code_required");
        error++;
      } else if (this.dealerBeforeChange.prmLppName != this.dealer.prmLppName) {
        haveChanges = true
      }

      if (!this.dealer.email) {
        this.formErrors.email = this.$ml.get("form_dealer_email_required");
        error++;
      } else {
        if (!emailValidate(this.dealer.email)) {
          this.formErrors.email = this.$ml.get("form_dealer_email_invalid");
          error++;
        }
        if (this.dealerBeforeChange.email != this.dealer.email) {
          haveChanges = true
        }
      }

      if (!this.dealer.ownerName) {
        this.formErrors.ownerName = this.$ml.get("form_dealer_owner_name_required");
        error++;
      } else if (this.dealerBeforeChange.ownerName != this.dealer.ownerName) {
        haveChanges = true
      }
      // if (!this.dealer.ownerIcOrId) {
      //   this.formErrors.ownerIcOrId = this.$ml.get("form_dealer_owner_ic_required");
      //   error++;
      // }

      if (!this.dealer.contactNum) {
        this.formErrors.contactNum = this.$ml.get("form_dealer_owner_contact_required");
        error++;
      } else {
        if (!phoneValidate(this.dealer.contactNum)) {
          this.formErrors.contactNum = this.$ml.get("invalid_phone_number");
          error++;
        }
        if (this.dealerBeforeChange.contactNum != this.dealer.contactNum) {
          haveChanges = true
        }
      }

      if (!this.dealer.accountManager) {
        this.formErrors.accountManager = this.$ml.get("form_dealer_acc_manager_required");
        error++;
      } else if (this.dealerBeforeChange.accountManager != this.dealer.accountManager) {
        haveChanges = true
      }

      if (!this.dealer.accreditationId) {
        this.formErrors.accreditationId = this.$ml.get("form_accreditation_required");
        error++;
      } else if (this.dealerBeforeChange.accreditationId == null
              || this.dealerBeforeChange.accreditationId && this.dealerBeforeChange.accreditationId.id != this.dealer.accreditationId.id) {
        haveChanges = true
      }

      if (this.dealerBeforeChange.countryCode && this.dealerBeforeChange.countryCode.value != this.dealer.countryCode.value) {
        haveChanges = true
      }
      if (this.dealerBeforeChange.accreditationId && this.dealerBeforeChange.accreditationId.id != this.dealer.accreditationId.id) {
        haveChanges = true
      }

      if (this.dealer.bulkUpload) {

        if (!this.dealer.bankAccNum) {
          this.formErrors.bankAccNum = this.$ml.get("form_dealer_acc_number_required");
          error++;
        }

        if (!this.dealer.bankAccNam) {
          this.formErrors.bankAccNam = this.$ml.get("form_dealer_acc_holder_name_required");
          error++;
        }

        if (!this.dealer.bankId) {
          this.formErrors.bankId = this.$ml.get("form_dealer_bank_name_required");
          error++;
        }
      }

      if (!this.dealer.reason && !this.isCapAdmin && !this.isCap) {
        this.formErrors.reason = "This field is required";
        error++;
      } else if (this.isChangeStatus) {
        // change status only need reason
        return true
      } else if (this.dealer.id != null && !haveChanges) {
        this.formErrors.reason = "There is no changes in the form. Please make some changes before submit";
        error++;
      }

      if (error) {
        return false;
      } else {
        return true;
      }
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (this.checkFormValidity(e)) {
        if (this.isChangeStatus) {
          this.doChangeStatus(null)
          return
        }
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch(NOTIFICATION_REQUEST, {
              message: this.$ml.get("request_submitting")
            });
            if (this.dealer.id) this.dealer.updateReason = this.dealer.reason
            this.$store.dispatch(DEALER_POST_REQUEST, this.dealer).then(res => {
              if (res.resultCode === 0) {
                this.success();
                if (this.dealer.id) {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("dealer_updated")
                  });
                } else {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("dealer_added")
                  });
                }
              }
            });
          }
        });
      }
    },
    getFilters() {
      this.countryOptions = [];
      this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET).then(res => {
        if (res.content) {
          res.content.countryDropdown.forEach(ctry => {
            if (ctry.name !== "CAP") {
              this.countryOptions.push(ctry);
            }
          });
          if(!this.dealer.id && !this.isCap && !this.isCapAdmin) {
            const country = res.content.countryDropdown.find(ctr => ctr.value === this.$store.getters.getCountryCode);
            this.dealer.countryCode = country;
          }
        }
      });
    },
    getBanks() {
      let pdata = { countryCode: this.$store.getters.getCountryCode };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;

          if (this.dealer.bankId > 0) {
            this.dealer.bankId = this.getSelectedBank(this.dealer.bankId);
          }
        }
        this.getBanksIsLoading = false;
      });
    },
    getAccreditationList() {
      this.isAccreditationLoading = true;
      this.$store.dispatch(ACCREDITATION_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.accreditationList = res.content;
          this.isAccreditationLoading = false;
          if (this.dealer.accreditationId) {
            this.dealer.accreditationId = this.getAccreditation(this.dealer.accreditationId);
          }
        } else {
          this.isAccreditationLoading = false;
        }
      });
    },
    getSelectedBank(id) {
      let c = this.banks.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getDealerById(id) {
      if (id > 0) {
        this.$store.dispatch(DEALER_REQUEST, { id: id }).then(res => {
          if (res.resultCode === 0) {
            const dealer = res.content;
            const country = this.countries.filter(ctry => ctry.text === dealer.countryName)[0];
            this.dealer.dealerName = dealer.dealerName;
            this.dealer.storeId = dealer.storeId;
            this.dealer.prmLppName = dealer.prmLppName;
            this.dealer.email = dealer.email;
            this.dealer.ownerName = dealer.ownerName;
            this.dealer.ownerIcOrId = dealer.ownerIcOrId;
            this.dealer.contactNum = dealer.contactNum;
            this.dealer.bulkUpload = dealer.bulkUpload;
            this.dealer.accreditationId = this.getAccreditation(dealer.accreditation);
            if (this.dealerAfterChanges.accreditationId) this.dealerAfterChanges.accreditationId = this.getAccreditation(this.dealerAfterChanges.accreditationId);
            this.dealer.accountManager = dealer.accountManager;
            this.dealer.bankId = dealer.bankId ? dealer.bankId : "";
            this.dealer.bankAccNum = dealer.bankAccNum;
            this.dealer.bankAccNam = dealer.bankAccHolderName;
            this.dealer.id = dealer.id;
            this.dealer.status = dealer.status;
            this.dealer.countryId = dealer.countryId || this.countries.find(el => {return el.text == dealer.countryName}).id;
            this.dealer.countryCode = {name: country.text, value: country.code};
            this.dealer.picList = dealer.picList;
            this.dealer.outletList = dealer.outletList;
            this.dealer.picListCount = dealer.picList.length;
            this.dealer.joinedDate = this.changeDate(dealer.joinedDate);
            this.dealer.salesPersonListCount = dealer.salespersonList.length;
            this.dealer.salespersonList = dealer.salespersonList;
            this.dealer.picList.forEach(row => {
              row.dealerOutlets.forEach(ol => {
                ol.picName = row.picName;
              });
            });
            this.dealer.outletListCount = dealer.outletList.length;
            if(this.selectedDealer.newApprovalRequest){
              this.dealer.reason = dealer.reason;
            }
            this.dealer.deactivationDate = dealer.deactivationDate ? this.changeDate(dealer.deactivationDate) : null
            this.dealer.activationDate = dealer.activationDate ? this.changeDate(dealer.activationDate) : null
            this.getBanks();
            this.dealerBeforeChange = cloneDeep(this.dealer)
          }
        });
      } else {
        this.getBanks();
      }
    },
    changeDate(date) {
      return convertDateFormat(date, "YYYY-MM-DD");
    },
    getDataRowById(arr, id) {
      let c = arr.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    checkForPendingApprovalFlag(id){
      this.$store.dispatch(GET_DEALER_PENDING_APPROVAL_FLAG, id).then(res => {
        if (res.resultCode === 0) {
          this.dealer.pendingApprovalStatus = res.content;
        }
      });
    }
  },
  created() {},
  async mounted() {
    this.isRendering = true
    this.userRole = this.$store.getters.getUserRole;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isCountryView = this.$store.getters.isCountryView;
    this.isCap = this.$store.getters.isCap;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCountryTrainer = this.$store.getters.isCountryTrainer;
    this.getFilters();
    await this.getCountries();
    this.dealer.countryId = this.$store.getters.getCountryId;
    if (this.dealer.id) {
      this.getDealerById(this.dealer.id);
      if(!this.isCountryTrainer){
        this.checkForPendingApprovalFlag(this.dealer.id);
      }
    } else {
      this.getBanks();
    }
    this.getAccreditationList();
    if (this.isPendingApprovalPage && !this.selectedDealer.newApprovalRequest) {
      this.getDealerChanges();
    }
    this.isRendering = false
  }
};
</script>

<style lang="scss" scoped>
.after-change {
  border: 1px solid red;
}
</style>
