<template>
  <div id="sub-region-target">
    <breadcrumb title="Dashboard" subtitle="Sub Region Target" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="col-lg-12">
                  <div class="row d-flex align-items-center justify-content-end mb-5">
                    <div v-if="!isCountryAdmin">
                      <button class="btn btn-sm btn-primary" @click="toggleModal">
                        <i class="fa fa-upload"></i> Upload
                      </button>
                    </div>
  <!--                  <div v-else>-->
  <!--                    <button class="btn btn-sm btn-primary" @click="downloadTemplate">-->
  <!--                      Download Template-->
  <!--                    </button>-->
  <!--                  </div>-->
                  </div>
                  <div class="row d-flex align-items-center mb-3">
                    <div v-if="isCapAdmin || isCap" class="form-group flex-grow-1 mr-4">
                      <label class="text-label">Countries</label>
                      <Multiselect
                              ref="multiselect-country"
                              v-model="target_country"
                              placeholder="Select Country"
                              :searchable="true"
                              :options="countries"
                              label="text"
                              name="country"
                              track-by="id"
                              select-label
                              deselect-label
                              :loading="isCountriesLoading"
                              @input="onFilterChanged()"
                      ></Multiselect>
                    </div>
                    <div class="form-group flex-grow-1 mr-4">
                      <label class="text-label">Year</label>
                      <select
                              id="year"
                              v-model="target_year"
                              class="form-control"
                              @change="onFilterChanged($event)"
                      >
                        <option v-for="(year, index) of fiscalYearList" :key="index">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group flex-grow-1 mr-4">
                      <label class="text-label">Quarter</label>
                      <select
                              id="type"
                              v-model="target_quarter"
                              class="form-control"
                              @change="onFilterChanged($event)"
                      >
                        <option v-for="(type, index) of quarterList" :key="index" :value="type.id">
                          {{ type.text }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group flex-grow-1">
                      <label class="text-label">Type</label>
                      <select
                              id="type"
                              v-model="target_type"
                              class="form-control"
                              @change="onFilterChanged($event)"
                      >
                        <option v-for="(type, index) of targetTypes" :key="index" :value="type.id">
                          {{ type.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center justify-content-end mb-5">
                    <div>
                      <button class="btn btn-sm btn-primary" :disabled="dataList.length === 0" @click="doExport">
                        <i class="fa fa-download"></i> Export
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <VueBootstrap4Table
                            :classes="classes"
                            :rows="dataList"
                            :columns="columns"
                            :config="config"
                            :total-rows="total"
                            @on-change-query="onChangeQuery"
                            @refresh-data="getDataList"
                    >
                      <template slot="sort-asc-icon">
                        <i class="mdi mdi-sort-ascending"></i>
                      </template>
                      <template slot="sort-desc-icon">
                        <i class="mdi mdi-sort-descending"></i>
                      </template>
                      <template slot="no-sort-icon">
                        <i class="mdi mdi-sort-variant"></i>
                      </template>
                      <template slot="empty-results">
                        <div class="bold-text">No Data Found!</div>
                      </template>

                      <template slot="id" slot-scope="props">
                        <span>{{ props.row.vbt_id }}</span>
                      </template>
                    </VueBootstrap4Table>
                  </div>
                </div>
              </div>
            </div>
            <BModal ref="upload-modal" size="xl" hide-footer title="Upload">
                <div v-if="isCapAdmin || isCap">
                  <regionUpload
                      :close-modal="toggleModal"
                      :sample-file="sampleFile"
                      :content="content"
                      :show-data-list="true"
                      :submit-button-text="'Submit'"
                      :file-columns="fileColumnStructure"
                      :file-data="fileColumnData"
                  />
                </div>
            </BModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import regionUpload from "../../components/target/region_upload_sr_target";
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import {COUNTRY_LIST_REQUEST, TARGET_SALE_TYPE_REQUEST} from "../../store/modules/utility/actions";
import {GET_SUB_REGION_TARGET_DATA_LIST_REQUEST} from "../../store/modules/target/actions";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import {export_json_to_excel} from "../../utils/Export2Excel";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';
import { FISCAL_YEAR_LIST } from "@/constants";

export default {
  name: "SubRegionTarget",
  components: {
    breadcrumb: breadcrumb,
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table,
    BModal: BModal,
    regionUpload: regionUpload
  },
  computed: {
    fileName() {
      return `Sub-region-target-${this.target_quarter}-${this.target_year}`;
    }
  },
  data() {
    return {
      sampleFile: "targetSubRegionUpload",
      content:
              "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the column labels in the sample file and make sure the file extension is .xlsx file.",
      targetTypes: [],
      quarterList: [
        { id: 1, text: "Q1" },
        { id: 2, text: "Q2" },
        { id: 3, text: "Q3" },
        { id: 4, text: "Q4" }
      ],
      fiscalYearList: FISCAL_YEAR_LIST,
      leaderList: this.leaders,
      dataList: [],
      postTargetData: {},
      target_year: null,
      target_type: null,
      target_quarter: null,
      target_country:null,
      total: 0,
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Sub Region Target",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "country",
          label: "Country",
          width: "30%"
        },
        {
          name: "subregionName",
          label: "Sub Region",
          width: "30%"
        },
        {
          name: "target",
          label: "Target",
          width: "30%"
        }
      ],
      tHeader: [
        "Country",
        "Sub Region",
        "Target"
      ],
      filterVal: [
        "country",
        "subregionName",
        "target"
      ],
      fileColumnData: [],
      fileColumnStructure: [
        "Country",
        "Sub Region Name",
        "Sub Region ID",
        "Fiscal Year",
        "Quarter",
        "Target Type",
        "Target"
      ],
      isCountryAdmin: false,
      isCapAdmin: false,
                isCap: false,
      countries: [],
      isCountriesLoading: false
    };
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    toggleModal() {
      this.$refs["upload-modal"].toggle("#toggle-btn");
    },
    doExport() {
      let data = this.formatJson(this.filterVal, this.dataList);
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: this.fileName,
        sheetName: this.fileName,
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "target") {
            return Number(v[j].replace(/,/g, ''));
          }
          return v[j];
        })
      );
    },
    getDataList(postData) {
      this.$store.dispatch(GET_SUB_REGION_TARGET_DATA_LIST_REQUEST, postData).then(res => {
        if (res.resultCode === 0) {
          // this.dataList = res.content.targetBySubregionList ? res.content.targetBySubregionList : [];
          this.formatDataArray(res.content.targetBySubregionList);
        }
      });
    },
    formatDataArray(data) {
      let rows = [];
      data.forEach(row => {
        let a = {
          country: this.getCountryName(row.countryId),
          subregionName: row.subregionName,
          target: this.filterTarget(row.countryId, row.data)
        };

        rows.push(a);
      });
      this.dataList = rows;
    },
    getCountryName(id){
      let name = this.countries.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (name[0]) {
        return name[0].text;
      }else{
        return 0;
      }
    },
    filterTarget(id, arr) {
      let list = arr.filter(function(elem) {
        if (elem.countryId === id) return elem;
      });
      if (list[0]) {
        return this.addCommaSeparator(list[0].target ? list[0].target : 0);
      } else {
        return 0;
      }
    },
    addCommaSeparator(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onFilterChanged() {
      const tdata = {
        country: this.target_country,
        year: this.target_year,
        type: this.target_type,
        quarter: this.target_quarter,
        monthly: false,
        weekly: true
      };
      this.getDataList(tdata);
    },
    setDefaultData() {
      if (!this.target_year) {
        this.target_year = this.fiscalYearList.find(item => item === defaultFiscalYear());
      }
      if (!this.target_type) {
        if (this.targetTypes[0]) {
          this.target_type = this.targetTypes[0].id;
        }
      }
      if (!this.target_quarter) {
        this.target_quarter = this.quarterList.find(item => item.text === defaultQuarter())["id"];
      }
      if(!this.isCapAdmin || !this.isCap){
        this.onFilterChanged();
      }
    },
    getTargetTypes() {
      this.$store.dispatch(TARGET_SALE_TYPE_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.targetTypes = res.content;
          if (!this.target_type) {
            this.target_type = this.targetTypes[0].id;
            this.setDefaultData();
          }
        }
      });
    },
    getCountries() {
      this.isCountriesLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          this.countries.unshift({id:0, text:"CAP"});
          if (!this.target_country) {
            this.target_country = this.countries[0];
            this.onFilterChanged();
          }
          this.isCountriesLoading = false;
        } else {
          this.isCountriesLoading = false;
        }
      });
    }
  },
  mounted() {
    this.isCapAdmin = this.$store.getters.isCapAdmin;
      this.isCap = this.$store.getters.isCap;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;

    if(this.isCapAdmin || this.isCap){
      this.getCountries();
    }
    this.getTargetTypes();
  }
};
</script>
