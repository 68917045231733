var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nk-sidebar"},[_c('div',{staticClass:"nk-nav-scroll"},[_c('ul',{staticClass:"metismenu",attrs:{"id":"menu"}},_vm._l((_vm.routerLinks),function(menu,index){return _c('li',{key:index,staticClass:"mega-menu mega-menu-lg",class:_vm.isActiveMenu(menu) ? 'lenovo-active active' : '',on:{"click":function($event){menu.subMenu.length == 0 ? _vm.setActiveMenu(menu, {}) : ''}}},[((_vm.isCountryTrainer && menu.subMenu.length > 0 && (index === 0 || index === 1)) || 
          (!_vm.isCountryTrainer && menu.subMenu.length > 0))?_c('a',{staticClass:"has-arrow link d-flex align-items-center",attrs:{"aria-expanded":"false"}},[_c('i',{class:_vm.getIcon(menu)}),_c('span',{staticClass:"nav-text"},[_vm._v(" "+_vm._s(menu.menu)+" ")]),(
              ((_vm.SRPCount > 0 || _vm.unknownSerialNumberCount > 0) && menu.menu === 'Data Management' && !_vm.isCapAdmin && !_vm.isCap) ||
              (_vm.ApprovalCount > 0 && menu.menu === 'Pending Approval' && !_vm.isCapAdmin && !_vm.isCap && !_vm.isMercLeader) ||
              ((_vm.approvalCountDealer > 0 || _vm.approvalCountDealerOutlet > 0 || _vm.approvalCountPromoter > 0 || _vm.approvalCountPromoterLeader > 0 || _vm.approvalCountMerchandiser > 0) 
                && menu.menu === 'Pending Approval' && (_vm.isCap || _vm.isCapAdmin)) || 
              (menu.menu === 'Pending Approval' && (_vm.isCountryAdmin || _vm.isGLNCountryAdmin) && (_vm.approvalCountPromoterUpdate > 0 || _vm.approvalCountPromoterLeaderUpdate > 0)) ||
              (menu.menu === 'Pending Approval' && (_vm.isMercLeader) && (_vm.approvalCountMerchandiser > 0))
            )?_c('span',{staticClass:"unread-signal-nav pull-right"}):_vm._e()]):_vm._e(),(menu.subMenu.length > 0)?_c('ul',{attrs:{"aria-expanded":"false"}},_vm._l((menu.subMenu),function(subm,index2){return _c('li',{key:index2,on:{"click":function($event){return _vm.setActiveMenu(menu, subm)}}},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":_vm.getRouterLink(subm)}},[_c('span',{on:{"click":_vm.loadNewMessage}},[_vm._v(_vm._s(subm.menu))]),(
                  (_vm.SRPCount > 0 && subm.menu === 'SRP List' && !_vm.isCapAdmin && !_vm.isCap) ||
                  (_vm.unknownSerialNumberCount > 0 && subm.menu === 'Unknown Serial Number' && !_vm.isCapAdmin && !_vm.isCap) ||
                  (_vm.ApprovalCount > 0 && menu.menu === 'Pending Approval' && subm.menu === 'Salesperson' && !_vm.isCapAdmin && !_vm.isCap) ||
                  ((_vm.approvalCountDealer > 0 || _vm.approvalCountDealerOutlet > 0 || _vm.approvalCountPromoter > 0 || _vm.approvalCountPromoterLeader > 0 || _vm.approvalCountMerchandiser > 0)
                    && menu.menu === 'Pending Approval' && subm.menu === 'Change Request' && (_vm.isCapAdmin || _vm.isCap)) || 
                  (menu.menu === 'Pending Approval' && subm.menu === 'Change Request' && (_vm.isCountryAdmin || _vm.isGLNCountryAdmin) && (_vm.approvalCountPromoterUpdate > 0 || _vm.approvalCountPromoterLeaderUpdate > 0))

                )?_c('span',{staticClass:"unread-signal-nav pull-right"}):_vm._e()])],1)}),0):_vm._e(),((_vm.isCountryTrainer && menu.subMenu.length == 0 && (index === 0 || index === 1)) || 
          (!_vm.isCountryTrainer && menu.subMenu.length == 0))?_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":_vm.getRouterLink(menu)}},[_c('i',{class:_vm.getIcon(menu),on:{"click":_vm.loadNewMessage}}),_c('span',{staticClass:"nav-text",on:{"click":_vm.loadNewMessage}},[_vm._v(_vm._s(menu.menu)+" "+_vm._s(" "))]),(
              _vm.unreadMessageCount > 0 && menu.menu === 'Message' && !_vm.isDealer && !_vm.isCapAdmin && !_vm.isCap
            )?_c('span',{staticClass:"unread-signal-nav pull-right"}):_vm._e()]):_vm._e()],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }