import { render, staticRenderFns } from "./lenovo-vs-competitor.vue?vue&type=template&id=7bfbc3cd&"
import script from "./lenovo-vs-competitor.vue?vue&type=script&lang=js&"
export * from "./lenovo-vs-competitor.vue?vue&type=script&lang=js&"
import style0 from "./lenovo-vs-competitor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports