import store from "@/store";
import {
    ROLE_CAP_ADMIN,
    LAYOUT_ADMIN,
  } from "../../constants";

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        let user = JSON.parse(localStorage.getItem("user"));
        let allowed = 0;
        for (let i = 0; i < to.meta.permission.length; i++) {
        if (to.meta.permission[i] === user.role && (
            user.name === "fabian@chimaera.my" ||
            user.name === "yuenwei.loh@globalline.my"
          )
        ) {
          allowed = 1;
          next();
          return;
        }
        }
        if (allowed === 0) {
        next("/not-allowed");
        return;
        }
    }
    next("/signin");
};

const operationRouter = {
    path: "/space",
    component: () => import("@/views/operation/index.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: LAYOUT_ADMIN,
      permission: [ ROLE_CAP_ADMIN ]
    }
  }


  export default operationRouter;