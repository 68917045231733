export const INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST = "INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST";
export const INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS = "INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS";
export const INCENTIVE_NORMAL_SALESPERSON_LIST_ERROR = "INCENTIVE_NORMAL_SALESPERSON_LIST_ERROR";

export const ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST = "ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST";
export const ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS = "ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS";
export const ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR = "ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR";

export const GET_INCENTIVE_DETAILS_BY_ID_REQUEST = "GET_INCENTIVE_DETAILS_BY_ID_REQUEST";
export const GET_INCENTIVE_DETAILS_BY_ID_SUCCESS = "GET_INCENTIVE_DETAILS_BY_ID_SUCCESS";
export const GET_INCENTIVE_DETAILS_BY_ID_ERROR = "GET_INCENTIVE_DETAILS_BY_ID_ERROR";

export const UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST = "UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST";
export const UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS = "UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS";
export const UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR = "UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR";

export const INCENTIVE_NEW_SALESPERSON_FILE_CHECK_REQUEST = "INCENTIVE_NEW_SALESPERSON_FILE_CHECK_REQUEST";
export const INCENTIVE_NEW_SALESPERSON_FILE_CHECK_SUCCESS = "INCENTIVE_NEW_SALESPERSON_FILE_CHECK_SUCCESS";
export const INCENTIVE_NEW_SALESPERSON_FILE_CHECK_ERROR = "INCENTIVE_NEW_SALESPERSON_FILE_CHECK_ERROR";

export const INCENTIVE_NEW_SALESPERSON_TEMPLATE_REQUEST = "INCENTIVE_NEW_SALESPERSON_TEMPLATE_REQUEST";
export const INCENTIVE_NEW_SALESPERSON_TEMPLATE_SUCCESS = "INCENTIVE_NEW_SALESPERSON_TEMPLATE_SUCCESS";
export const INCENTIVE_NEW_SALESPERSON_TEMPLATE_ERROR = "INCENTIVE_NEW_SALESPERSON_TEMPLATE_ERROR";