<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <VueBootstrap4Table
          :classes="classes"
          :rows="filterState"
          :columns="columns"
          :config="config"
          :total-rows="total"
          @on-change-query="onChangeQuery"
          @refresh-data="getOutletList"
        >
          <template slot="sort-asc-icon">
            <i class="mdi mdi-sort-ascending"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="mdi mdi-sort-descending"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="mdi mdi-sort-variant"></i>
          </template>
          <template slot="empty-results">
            <div
              v-if="!isDataLoading"
              class="bold-text"
              v-text="$ml.get('no_outlet_found')"
            ></div>
            <div
              v-if="isDataLoading"
              class="loading mt-3"
            ></div>
          </template>

          <template slot="promoterCount" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 2)">
              <span style="color:#F70201">
                {{ props.row.promoterCount }}
              </span>
            </div>
          </template>

          <template slot="salespersonCount" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 3)">
              <span style="color:#F70201">
                {{ props.row.salespersonCount }}
              </span>
            </div>
          </template>

          <template slot="dealerOutletCode" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 4)">
              <span style="color:#F70201">
                {{ props.row.dealerOutletCode }}
              </span>
            </div>
          </template>

          <template slot="storeIdentity" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="props.row.storeIdentity ? toggleKeyStoreModal(props.row) : null ">
              <span :style="props.row.storeIdentity ? {'color':'#F70201'} : ''">
                {{ props.row.storeIdentity || '-' }}
              </span>
            </div>
          </template>

          <template slot="name" slot-scope="props">
            <div class="inner-data">
              <span>
                {{ titleCase(props.row.name) }}
              </span>
            </div>
          </template>          
          <template slot="dealerName" slot-scope="props">
            <div class="inner-data">
              <span>{{ titleCase(props.row.dealerName) }}</span>
            </div>
          </template>

          <template slot="status" slot-scope="props">
            <lnvStatusTextBox :status="props.row.status" />
          </template>

          <!-- <template slot="Action" slot-scope="props">
            <div
              class="inner-buttons btn-group float-right"
              role="group"
            >
              <button
                v-if="canEdit"
                class="btn btn-warning btn-xs"
                @click="toggleModal(props.row, 4)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
          </template> -->
        </VueBootstrap4Table>
      </div>
    </div>
            

    
  </div>
</template>

<script>
import VueBootstrap4Table from "../ui/VueBootstrap4Table";
import lnvStatusTextBox from "../ui/statusTextBox";

export default {
  name: "OutletComponent",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    lnvStatusTextBox: lnvStatusTextBox
  },
  props: {
    total: {type: Number, default: 0},
    filterState: {type: [Array, Boolean], default: false},
    isDataLoading: {type: Boolean, default: false},

  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Outlets",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false,
          init: {
            value : ""
          }
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        // {
        //   name: "id",
        //   label: "ID",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        //   initial_sort: true,
        //   initial_sort_order: "asc",
        //   visibility: false,
        // },
        {
          name: "countryCode",
          label: "CTRY",
          sort: true,
          width: "4%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "dealerName",
          label: "Dealer Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "lppCode",
          label: "MDM Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "name",
          label: "Outlet Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "dealerOutletCode",
          label: "Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "region",
          label: "Region",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "subRegion",
          label: "Sub Region",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "type",
          label: "Outlet Type",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "focusProduct",
          label: "Focus Product",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "storeIdentity",
          label: "Store Identity",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }
      ],
      isCountryTrainer: false,
      isLeader: false,
      isMercLeader: false
    };
  },
  methods: {
    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    },
    onChangeQuery(){
      this.$emit("on-change-query");
    },
    getOutletList(){
      this.$emit("get-outlet-list");
    },
    toggleModal(row, view){
      this.$emit("toggle-modal", row, view);
    },
    toggleKeyStoreModal(row){
      this.$emit("toggle-key-store", row);
    },
  },
  mounted() {
    this.isCountryTrainer = this.$store.getters.isCountryTrainer;
    this.isLeader = this.$store.getters.isPromoLeader;
    this.isMercLeader = this.$store.getters.isMercLeader;
    if (!this.isLeader && !this.isMercLeader) {
      this.columns.push({
          name: "promoterCount",
          label: "# Promoters",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "salespersonCount",
          label: "# Salespersons",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
      )
    }
    if(!this.isMercLeader) {
     this.columns.push({
        name: "actual",
        label: "Actual",
        sort: true,
        row_text_alignment: "text-left",
        column_text_alignment: "text-left",
      })
    }
    this.columns.push({
      name: "status",
      label: "Outlet Status",
      sort: true,
      row_text_alignment: "text-left",
      column_text_alignment: "text-left",
    })
    if(this.$route.params.dealerOutletCode !== undefined) {
      this.config.global_search.init.value = this.$route.params.dealerOutletCode
      this.onChangeQuery();
    }
  },
  created() {
  }
};
</script>

<style scoped>
.barcode-icon {
  font-size: 3em;
}
.table>thead>tr>th {
  font-weight: 500;
  font-size: 13px;
}
.row-btn-wrapper{
  margin: auto 0px 0px auto;
}
</style>
