import request from "@/utils/request";
import {
  PENDING_SALESPERSON_LIST_SERVICE,
  APPROVE_REJECT_SALESPERSON_SERVICE,
  GET_PROMOTER_LEADERS_SERVICE,
  GET_SALESPERSON_LIST_SERVICE,
  ADD_PROMOTER_LEADER_SERVICE,
  ADD_PROMOTER_SERVICE,
  ADD_SALESPERSON_SERVICE,
  UPDATE_PROMOTER_LEADER_SERVICE,
  UPDATE_PROMOTER_SERVICE,
  UPDATE_SALESPERSON_SERVICE,
  GET_PROMOTERS_WITH_OUTLETS_SERVICE,
  GET_PROMOTERS_LIST_BY_PROMOTER_SUPERVISOR_ID,
  GET_PROMOTERS_LIST_SERVICE,
  GET_ACTIVE_PROMOTERS_WITH_OUTLETS_SERVICE,
  ASSIGN_PROMOTER_OUTLET_SERVICE,
  UNASSIGN_PROMOTER_OUTLET_SERVICE,
  ADD_STATE_MANAGER_SERVICE,
  UPDATE_STATE_MANAGER_SERVICE,
  GET_STATE_MANAGER_LIST_SERVICE,
  GET_REGIONAL_MANAGER_LIST_SERVICE,
  UPDATE_REGIONAL_MANAGER_SERVICE,
  ADD_REGIONAL_MANAGER_SERVICE,
  GET_PROMOTER_LIST_SERVICE, GET_PROMOTERS_SUPERVISOR_LIST_SERVICE,
  GET_LIST_SALESPERSON_SERVICE,
  API_GET_PENDING_DEALER_LIST,
  API_GET_PENDING_PROMOTER_LEADER_LIST,
  API_GET_PENDING_DEALER_OUTLET_LIST,
  API_GET_PENDING_PROMOTER_LIST,
  API_GET_PENDING_MERCHANDISER_LIST,
  API_GET_PENDING_MERCHANDISER_LEADER_LIST,
  API_UPDATE_APPROVAL_PROMOTER,
  API_DEALER_PENDING_APPROVAL_FLAG,
  API_DEALER_OUTLET_PENDING_APPROVAL_FLAG,
  API_PROMOTER_PENDING_APPROVAL_FLAG,
  API_PROMOTER_LEADER_PENDING_APPROVAL_FLAG,
  API_UPDATE_APPROVAL_DEALER_OUTLET,
  API_UPDATE_APPROVAL_DEALER,
  API_UPDATE_APPROVAL_MERCHANDISER,
  API_UPDATE_APPROVAL_MERCHANDISER_LEADER,
  API_UPDATE_APPROVAL_PROM,
  EDIT_PROMOTER_OUTLET_SERVICE,
  API_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG,
  GET_MERCHANDISER_LIST_SERVICE,
  GET_MERCHANDISER_LEADER_LIST_SERVICE,
  UPDATE_MERCHANDISER_SERVICE,
  UPDATE_MERCHANDISER_LEADER_SERVICE,
  ADD_MERCHANDISER_SERVICE,
  ADD_MERCHANDISER_LEADER_SERVICE,
  API_MERCHANDISER_PENDING_APPROVAL_FLAG,
  API_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG,
  GET_MERCHANDISER_BY_LEADER_ID
} from "../constants/services";

import { DEFAULT_TIMEOUT, EXTENDED_TIMEOUT } from "../constants/index";

export async function getPendingSalespList(postData) {
  return request({
    url: PENDING_SALESPERSON_LIST_SERVICE + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function approveOrRejectSalesperson(postData) {
  const data = {
    id: postData.id,
    action: postData.action ? true : false
  };
  return await request({
    url: APPROVE_REJECT_SALESPERSON_SERVICE,
    method: "put",
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getStaffList(postData) {
  let countryId = postData.countryId ? "/" + postData.countryId : "";

  var url = "";
  if (postData.type == "leaders") {
    url = GET_PROMOTER_LEADERS_SERVICE + countryId;
  } else if (postData.type == "promoters") {
    url = GET_PROMOTERS_WITH_OUTLETS_SERVICE + countryId; //GET_PROMOTER_LIST_SERVICE;
  } else if (postData.type == "salespersons") {
    url = GET_SALESPERSON_LIST_SERVICE + countryId;
  } else if (postData.type == "stateManagers") {
    url = GET_STATE_MANAGER_LIST_SERVICE + countryId;
  } else if (postData.type == "regionalManagers") {
    url = GET_REGIONAL_MANAGER_LIST_SERVICE + countryId;
  }

  return await request({
    url: url,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPromoterListByPromoterSupervisorId(postData) {
  return request({
    url: `${GET_PROMOTERS_LIST_BY_PROMOTER_SUPERVISOR_ID}/${postData.sif}/promoters/all${postData.fyf ? `?fyf=${postData.fyf}` : ``}${postData.qf ? `&qf=${postData.qf}` : ``}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getStaffPromoterList(postData) {
  let dealerFilter = "";
  let accIdFilter = "";
  let outletIdFilter = "";

  if(postData.df !== undefined) { dealerFilter = "&df=" + postData.df }
  if(postData.pif !== undefined) { accIdFilter = "&pif=" + postData.pif }
  if(postData.oif !== undefined) { outletIdFilter = "&oif=" + postData.oif }
  let url;
  if(postData.ccf){
    url = GET_PROMOTERS_LIST_SERVICE + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf + dealerFilter + accIdFilter + outletIdFilter;
  } else {
    url = GET_PROMOTERS_LIST_SERVICE + "?pif=" + postData.pif;
  }

  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getStaffSalespersonList(postData) {
  let dealerFilter = "";
  let accIdFilter = "";
  let outletIdFilter = "";
  if(postData.df !== undefined) { dealerFilter = "&df=" + postData.df }
  if(postData.spif !== undefined) { accIdFilter = "&spif=" + postData.spif }
  if(postData.oif !== undefined) { outletIdFilter = "&oif=" + postData.oif }
  return request({
    url: GET_LIST_SALESPERSON_SERVICE + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf + dealerFilter + accIdFilter + outletIdFilter,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getStaffPromoterSupervisorList(postData) {
  return request({
    url: GET_PROMOTERS_SUPERVISOR_LIST_SERVICE + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
export async function getActivePromoters(postData) {
  return await request({
    url: GET_ACTIVE_PROMOTERS_WITH_OUTLETS_SERVICE,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addOrUpdateStaff(postData) {
  let url = "";
  let method = "post";
  if (postData.type == "leaders") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_PROMOTER_LEADER_SERVICE;
      method = "put";
    } else {
      url = ADD_PROMOTER_LEADER_SERVICE;
      method = "post";
    }
  } else if (postData.type == "promoters") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      // postData.data.createdCountryId = postData.data.createdCountryId.id;
      url = UPDATE_PROMOTER_SERVICE;
      method = "put";
    } else {
      // postData.data[0].createdCountryId = postData.data[0].createdCountryId.id;
      url = ADD_PROMOTER_SERVICE;
      method = "post";
    }
  } else if (postData.type == "salespersons") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_SALESPERSON_SERVICE;
      method = "put";
    } else {
      url = ADD_SALESPERSON_SERVICE;
      method = "post";
    }
  } else if (postData.type === "stateManager") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_STATE_MANAGER_SERVICE;
      method = "put";
    } else {
      url = ADD_STATE_MANAGER_SERVICE;
      method = "post";
    }
  } else if (postData.type === "regionalManager") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_REGIONAL_MANAGER_SERVICE;
      method = "put";
    } else {
      url = ADD_REGIONAL_MANAGER_SERVICE;
      method = "post";
    }
  } else if (postData.type === "merchandiser") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_MERCHANDISER_SERVICE;
      method = "put";
    } else {
      url = ADD_MERCHANDISER_SERVICE;
      method = "post";
    }
  } else if (postData.type === "merchandiserLeader") {
    if (postData.staffId > 0) {
      postData.id = postData.staffId;
      url = UPDATE_MERCHANDISER_LEADER_SERVICE;
      method = "put";
    } else {
      url = ADD_MERCHANDISER_LEADER_SERVICE;
      method = "post";
    }
  }

  return await request({
    url: url,
    method: method,
    data: postData.data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addOrUpdatePromoterOutlet(postData) {
  let url = ASSIGN_PROMOTER_OUTLET_SERVICE;
  let method = "post";
  if (postData.type === 0) {
    url = UNASSIGN_PROMOTER_OUTLET_SERVICE;
  } else if(postData.type === 1) {
    url = ASSIGN_PROMOTER_OUTLET_SERVICE;
  } else {
    url = EDIT_PROMOTER_OUTLET_SERVICE;
    method = "put";
  }
  return await request({
    url: url,
    method: method,
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });

}

export async function getAllPromoters() {
  return await request({
    url: GET_PROMOTER_LIST_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPendingDealerListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_DEALER_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPendingPromoterLeaderListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_PROMOTER_LEADER_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPendingDealerOutletListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_DEALER_OUTLET_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: EXTENDED_TIMEOUT
  });
}

export async function getPendingPromoterListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_PROMOTER_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPendingMerchandiserListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_MERCHANDISER_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPendingMerchandiserLeaderListRequest(postData) {
  return await request({
    url: `${API_GET_PENDING_MERCHANDISER_LEADER_LIST}${postData.ccf ? `?ccf=${postData.ccf}` : ``}` ,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalPromoterRequest(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_PROMOTER}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalDealerOutlet(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_DEALER_OUTLET}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalDealer(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_DEALER}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalMerchandiser(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_MERCHANDISER}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalMerchandiserLeader(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_MERCHANDISER_LEADER}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateApprovalProm(postData) {
  return await request({
    url: `${API_UPDATE_APPROVAL_PROM}${`/${postData.id}`}${`/${postData.action}`}` ,
    method: "put",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerPendingApprovalFlag(id) {
  return await request({
    url: `${API_DEALER_PENDING_APPROVAL_FLAG}${`/${id}`}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerOutletPendingApprovalFlag(id) {
  return await request({
    url: `${API_DEALER_OUTLET_PENDING_APPROVAL_FLAG}${`/${id}`}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPromoterPendingApprovalFlag(id) {
  return await request({
    url: `${API_PROMOTER_PENDING_APPROVAL_FLAG}${`/${id}`}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPromoterLeaderPendingApprovalFlag(id) {
  return await request({
    url: `${API_PROMOTER_LEADER_PENDING_APPROVAL_FLAG}${`/${id}`}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getPromoterOutletAssignmentPendingApprovalFlag(id) {
  return await request({
    url: `${API_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG}${`/${id}`}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getAllMerchandisers(postData) {
  let url = `${GET_MERCHANDISER_LIST_SERVICE
  }?fyf=${postData.fyf
  }&qf=${postData.qf
  }`
  if (postData.countryId != null) url += `&countryId=${postData.countryId}`
  if (postData.pif !== undefined) { url += "&pif=" + postData.pif }
  
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getAllMerchandiserLeaders(postData) {
  let url = `${GET_MERCHANDISER_LEADER_LIST_SERVICE
  }?fyf=${postData.fyf
  }&qf=${postData.qf
  }`
  if (postData.countryId != null) url += `&countryId=${postData.countryId}`
  if (postData.pif !== undefined) { url += "&pif=" + postData.pif }

  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getMerchandiserPendingApprovalFlag(id) {
  return await request({
    url: `${API_MERCHANDISER_PENDING_APPROVAL_FLAG}/${id}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getMerchandiserLeaderPendingApprovalFlag(id) {
  return await request({
    url: `${API_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG}/${id}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getMerchandiserByLeaderId(params) {
  return await request({
    url: `${GET_MERCHANDISER_BY_LEADER_ID}`,
    method: "get",
    params,
    timeout: DEFAULT_TIMEOUT
  });
}
