<template>
	<b-tabs id="visit-task-tabs" v-model="activeTab">
		<!-- LOADING -->
		<div v-if="isLoading" class="loading mt-3"></div>

		<!-- CONTENT -->
		<template v-else>
			<b-tab-item v-for="(task, index) in taskList" :key="index" :label="task.title">
				<div v-if="activeTab === index">
					<b-row>
						<ul class="task">
							<!-- subSections -->
							<li v-for="(data, idx) in task.subSections" :key="idx" class="question">
								<div class="d-flex justify-content-start w-100">
									<span class="task-number">{{ data.id }}</span>
									<span class="w-100">
										<!-- [last updated on 06/09/22 : Custom text] -->
										<!-- Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY -->
										<b v-if="data.code === 'IDENTIFY_LENOVO_DEVICES_ON_DISPLAY'">Lenovo Devices on Display</b>
										<!-- Q4:LENOVO_ACCESSORIES_ON_DISPLAY  -->
										<b v-else-if="data.code === 'LENOVO_ACCESSORIES_ON_DISPLAY'">Lenovo Accessories on Display</b>
										<!-- Q5:LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)  -->
										<b v-else-if="data.code === 'LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)'">Lenovo Demo Unit Share of Space</b>
										<!-- Q6:IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY  -->
										<b v-else-if="data.code === 'IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY'">Competitor Brands on Display</b>
										<!-- Q7:IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY  -->
										<b v-else-if="data.code === 'IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY'">Competitor Brands with their Gaming Devices on Display</b>
										<!-- Q8:LENOVO_FIXTURE_WEAR_&_TEAR  -->
										<b v-else-if="data.code === 'LENOVO_FIXTURE_WEAR_&_TEAR'">Types of Lenovo Fixtures in-store</b>
										<!-- Q9:LENOVO_STATUS_OF_AREA_CLEANLINESS  -->
										<b v-else-if="data.code === 'LENOVO_STATUS_OF_AREA_CLEANLINESS'">Area Cleanliness</b>
										<!-- Q10:PROMINENT_LENOVO_BRANDING  -->
										<b v-else-if="data.code === 'PROMINENT_LENOVO_BRANDING'">Lenovo branding (e.g. tabletop lightbox, KV lightbox, bunting, etc.) is not being blocked by other POSM, devices, other brands, etc., and immediately adjust if Lenovo branding being blocked</b>
										<!-- Q11:ADEQUATE_SPACING_BETWEEN_DEVICES  -->
										<b v-else-if="data.code === 'ADEQUATE_SPACING_BETWEEN_DEVICES'">All Devices are Displayed with Adequate Spacing in Between</b>
										<!-- Q12:WELL_ORGANISED_FLYERS_/CATALOGUES  -->
										<b v-else-if="data.code === 'WELL_ORGANISED_FLYERS_/CATALOGUES'">Flyers/Catalogues are Well-organised on Tabletop or in Flyer Holder</b>
										<!-- Q13:SHOPFRONT/LENOVO_AREA_SIGNAGE  -->
										<b v-else-if="data.code === 'SHOPFRONT/LENOVO_AREA_SIGNAGE'">Lenovo Signage at Shopfront/Lenovo Area</b>
										<!-- Q14:NO_MISPLACE_OF_PRODUCTS  -->
										<b v-else-if="data.code === 'NO_MISPLACE_OF_PRODUCTS'">NO Misplacement of Products on Display (e.g. Displaying Non-Legion Model on Legion Glorifier/FSU, Non-Yoga model on Yoga Glorifier/FSU, etc.)</b>
										<!-- Q16:SPECS_&_PRICE_INFORMATION  -->
										<b v-else-if="data.code === 'SPECS_&_PRICE_INFORMATION'">Specs and Price Information of All Devices</b>
										<!-- Q17:POSM_PLACEMENT  -->
										<b v-else-if="data.code === 'POSM_PLACEMENT'">POSMs in-store</b>
										<!-- Q18:COMPETITOR_HARD_BRANDING  -->
										<b v-else-if="data.code === 'COMPETITOR_HARD_BRANDING'">Competitor Brands' Hard Branding</b>
										<!-- Q19:COMPETITOR_PROMOTION  -->
										<b v-else-if="data.code === 'COMPETITOR_PROMOTION'">Competitor Brands' On-going Promotion in-store</b>

										<b v-else>{{ data.title }}</b>

										<b-badge v-if="data.showNumber && data.code !== 'IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY'" pill variant="dark" class="ml-2">

											<!-- Q4:LENOVO_ACCESSORIES_ON_DISPLAY  -->
											<!-- [last updated on 16/08/22 : no more units number per product , only total products' amount] -->
											<template v-if="data.code === 'LENOVO_ACCESSORIES_ON_DISPLAY'">
												{{ getTotalAccessoryAmount(data.tasksWithResponses[0].subTasks) }}
											</template>

											<!-- Q5:LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)  -->
											<template v-else-if="data.code === 'LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)'">
												<!-- NOTE: (3a + 3b) / (3a + 3b + Q5) * 100 [last updated on 16/08/22] -->
												{{ data.tasksWithResponses.length > 0 ? getDemoUnitPercentage(data.tasksWithResponses[0].response,task.subSections[0].tasksWithResponses) : getDemoUnitPercentage(0,task.subSections[0].tasksWithResponses) }} %
											</template>

											<!-- Q6:IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY  -->
											<template v-else-if="data.code === 'IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY'">
												{{ getCompetitorAmount(data.tasksWithResponses[0].subTasks) }}
											</template>

											<!-- Q7:IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY [last updated on 16/08/22 : manually remove the calculation, no need show total amount] -->
											<!-- <template v-else-if="data.code === 'IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY'">
												{{ getOtherDevicesCount(data.tasksWithResponses[0]) }}
											</template> -->

											<!-- Q8:LENOVO_FIXTURE_WEAR_&_TEAR  -->
											<template v-else-if="data.code === 'LENOVO_FIXTURE_WEAR_&_TEAR'">
												{{ data.tasksWithResponses[0].subTasks[0].response ? data.tasksWithResponses[0].subTasks.length : 0 }}
											</template>

											<template v-else>										
												&nbsp; {{ data.tasksWithResponses[0].response ? data.tasksWithResponses.length :0 }} &nbsp;
											</template>
										</b-badge>
										<!-- Q2:LENOVO_FIXTURE_SHARE_OF_SPACE  -->
										<!-- [last updated on 06/09/22 : manually count % in web-portal] -->
										<b-badge v-if="data.code === 'LENOVO_FIXTURE_SHARE_OF_SPACE'" pill variant="dark" class="ml-2">
											{{ data.tasksWithResponses.length > 0 ? getFixturePercentage(data.tasksWithResponses[0].response) : getFixturePercentage(0) }} %
										</b-badge>

										<template v-if="data.code === 'LENOVO_FIXTURE_SHARE_OF_SPACE'">
											/
											<b>With KV</b>
											<b-badge pill variant="dark" class="ml-2">
												{{ data.tasksWithResponses.length > 0 ? getFixtureKeyVisualPercentage(data.tasksWithResponses[0].response) : getFixtureKeyVisualPercentage(0) }} %
											</b-badge>
										</template>
										
										<!-- Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY  -->
										<!-- [last updated on 06/09/22 : manually count old + new Gen] -->
										<b-badge v-if="data.code === 'IDENTIFY_LENOVO_DEVICES_ON_DISPLAY'" pill variant="dark" class="ml-2">
											{{ data.tasksWithResponses.length > 0 ? getTotalGen(data.tasksWithResponses[0].subTasks,data.tasksWithResponses[1].subTasks) : 0 }}
										</b-badge>
										<!-- Q17:POSM_PLACEMENT  -->
										<!-- [last updated on 06/09/22 : count YES  ] -->
										<b-badge v-if="data.code === 'POSM_PLACEMENT'" pill variant="dark" class="ml-2">
											{{ getTotalPOSM(data.tasksWithResponses) }}
										</b-badge>
									</span>
								</div>

								<!-- subSections -> tasksWithResponses -->
								<template v-if="data.tasksWithResponses.length > 0">
									<div v-for="(tasksWithResponses, i) in data.tasksWithResponses" :key="i" 
										:class="[ 
											{'clearfix response': tasksWithResponses.type !== 'GROUP_PHOTO' && data.code !== 'TOTAL_%_LENOVO_PCS_TURNED-ON'},
											{'special-res': tasksWithResponses.type === 'GROUP_PHOTO' && tasksWithResponses.subTasks[0].response && data.code !== 'LOCATION_OF_LENOVO_ZONE'},
											{'clearfix responses-nl': data.code === 'LOCATION_OF_LENOVO_ZONE'},
											{'responses-nl': data.code === 'TOTAL_%_LENOVO_PCS_TURNED-ON'},
											]"
									>																			
										<!-- Question -->
										<span v-if="tasksWithResponses.type !== 'DROPDOWN' && tasksWithResponses.type !== 'GROUP_PHOTO'" :class="{'w-100 d-block': data.code !== 'POSM_PLACEMENT'}">
											<!-- Q17:POSM_PLACEMENT -->
											<span v-if="data.code === 'POSM_PLACEMENT'">{{ tasksWithResponses.text }}</span>
											
											<!-- [last updated on 06/09/22 : Custom text] -->
											<!-- Q2:LENOVO_FIXTURE_SHARE_OF_SPACE -->
											<b v-else-if="data.id === 3 && data.code === 'LENOVO_FIXTURE_SHARE_OF_SPACE'">2a. Number of total in-store fixtures (i.e. Lenovo + Competitor + Store fixtures)</b>
											<b v-else-if="data.id === 421 && data.code === 'LENOVO_FIXTURE_SHARE_OF_SPACE'">2b. Number of total instore Key Visuals (Lenovo KV branding only)</b>
											<!-- Q5:LENOVO_SHARE_OF_SPACE_(DEMO_UNITS) -->
											<b v-else-if="data.code === 'LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)'">Number of total in-store devices on display</b>

											<b v-else>{{ tasksWithResponses.text }}</b>

											<!-- GROUP TITLE (Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY)  -->
											<!-- [last updated on 26/08/22 : removed GROUP TITLE 2D Array ] -->
											<b-badge v-if="tasksWithResponses.showNumber && data.code === 'IDENTIFY_LENOVO_DEVICES_ON_DISPLAY'" pill variant="dark" class="ml-2">
												{{ getTotalGenModelAmount(tasksWithResponses.subTasks) }}
											</b-badge>
											<!-- /GROUP TITLE (Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY)  -->
											<b-badge v-else-if="tasksWithResponses.showNumber" pill variant="dark">
												&nbsp;{{ tasksWithResponses.subTasks.length || 0 }}&nbsp;
											</b-badge>
										</span>										
										<!-- /Question -->
										<!-- Response -->
										<span v-if="tasksWithResponses.response" :class="{'w-100 d-block':data.code !=='TOTAL_%_LENOVO_PCS_TURNED-ON'}">
											
											<!-- Q5:LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)  -->
											<template v-if="data.code === 'LENOVO_SHARE_OF_SPACE_(DEMO_UNITS)'">
												<!-- NOTE: (3a-New + 3b-Old) + competitor [last updated on 06/09/22] -->
												{{ getTotalDemoUnit(tasksWithResponses.response) }} 
											</template>
											<template v-else>
												{{ tasksWithResponses.response }}
											</template>
										</span>
										<span v-else-if="haveNoResponse(tasksWithResponses)"> N/A </span>
										<!-- /Response -->

										<!-- Sub-question  -->
										<template v-if="tasksWithResponses.subTasks.length > 0">
											<span v-for="(subTasks, ii) in tasksWithResponses.subTasks" :key="ii" 
												:class="
													[data.code !=='LENOVO_ACCESSORIES_ON_DISPLAY' && 
													data.code !=='IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY' && 
													data.code !=='TOTAL_%_LENOVO_PCS_TURNED-ON' && 
													tasksWithResponses.type !== 'GROUP_PHOTO' &&
													tasksWithResponses.type !== 'GROUP_TITLE' ? 'responses' : '',
													data.code === 'LENOVO_STATUS_OF_AREA_CLEANLINESS' ? 'float-left w-100' : '']
												"
											>	
												
												<!-- GROUP TITLE (Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY)  -->
												<template v-if="data.code ==='IDENTIFY_LENOVO_DEVICES_ON_DISPLAY'">
													<!-- NOTE: 
														NewGen : id = 407 (number) & id = 5 (product) ; 
														OldGen : id = 408 (number)& id = 7 (product) -->
													<template v-if="subTasks.id === 5 || subTasks.id === 7">
														{{ subTasks.response }}
													</template>
													<b-badge v-else-if="subTasks.id === 407 || subTasks.id === 408" pill variant="light" class="badge-xs text-white">&nbsp; {{ subTasks.response }} &nbsp;</b-badge>
													<!-- Specify details for model -->
													<small v-if="subTasks.type === 'TEXT'">({{ subTasks.response }}) </small>
													<br v-if="subTasks.id === 407 || subTasks.id === 408">
												</template>
												<!-- /GROUP TITLE (Q3:IDENTIFY_LENOVO_DEVICES_ON_DISPLAY)  -->

												<!-- GROUP TITLE (Q4:LENOVO_ACCESSORIES_ON_DISPLAY)  -->
												<template v-else-if="tasksWithResponses.type === 'GROUP_TITLE' && data.code ==='LENOVO_ACCESSORIES_ON_DISPLAY' && subTasks.response">
													<!-- NOTE: id = 10 (number); id = 11 (product); id = 12 (others) -->
													<template v-if="subTasks.id === 11">
														{{ subTasks.response }}
														<br>
													</template>
													<small v-if="subTasks.id === 12"> ( {{ subTasks.response }} ) </small>
													<!-- [last updated on 16/08/22 : no more units number per product , only total products' amount] -->
													<!-- <b-badge v-else-if="subTasks.id === 10" pill variant="light" class="badge-xs text-white">&nbsp; {{ subTasks.response }} &nbsp;</b-badge>
													<br v-if="subTasks.id === 10"> -->
												</template>
												<!-- /GROUP TITLE (Q4:LENOVO_ACCESSORIES_ON_DISPLAY)  -->

												<!-- Q6:IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY -->
												<template v-else-if="data.code ==='IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY'">
													<!-- NOTE: id = 349 (number); id = 18 (brand); id = 19 (On/Off); id = 358 (Specify details)	 -->
													<!-- [last updated on 16/08/22 : remove all the questions, only remain response ] -->
													<!-- <span v-if="ii === 0 && tasksWithResponses.subTasks" class="w-100 d-block text-gray sub-question-title">
														{{ tasksWithResponses.subTasks[0] ? tasksWithResponses.subTasks[0].text : '' }} <br>
														{{ tasksWithResponses.subTasks[1] ? tasksWithResponses.subTasks[1].text : '' }} <br>
														{{ tasksWithResponses.subTasks[2] ? tasksWithResponses.subTasks[2].text : '' }} <br>
													</span> -->
													<template v-if="subTasks.id === 18">&nbsp;{{ subTasks.response }}&nbsp;</template>
													<b-badge v-else-if="subTasks.id === 19" variant="none" class="badge-xs ml-1 border">{{ subTasks.response }}</b-badge>
													<b-badge v-else-if="subTasks.id === 349" pill variant="light" class="badge-xs text-white">{{ subTasks.response }}</b-badge>	
													<small v-else-if="subTasks.id === 358 && subTasks.response"> ( {{ subTasks.response }} ) </small>		
													<br v-if="subTasks.id === 19">
												</template>
												<!-- /Q6:IDENTIFY_COMPETITOR_BRANDS_ON_DISPLAY -->

												<!-- Q7:IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY  -->
												<template v-else-if="data.code ==='IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY'">												
													<br v-if="ii != 0 && subTasks.family === 'BRANDS'">
													<template v-if="subTasks.family">{{ subTasks.response }} </template>
													<template v-if="subTasks.family !== 'BRANDS' && subTasks.response && subTasks.family && isLastElement(tasksWithResponses.subTasks[ii+1])"> , </template>
													<template v-if="subTasks.family === 'BRANDS' && subTasks.response"> > </template>
													<small v-if="!subTasks.family && subTasks.response">({{ subTasks.response }})</small>
												</template>
												<!-- /Q7:IDENTIFY_COMPETITOR_BRANDS_WITH_THEIR_GAMING_DEVICES_ON_DISPLAY  -->

												<!-- Q15:TOTAL_%_LENOVO_PCS_TURNED-ON  -->
												<template v-else-if="data.code ==='TOTAL_%_LENOVO_PCS_TURNED-ON'">
													<!-- NOTE: id = 291; text: "Is Turned-On" ; type: "RADIO" -->
													<b-badge v-if="subTasks.id === 291" pill variant="light" class="badge-xs text-white">{{ subTasks.response }}</b-badge>
													<br>
												</template>
												<!-- /Q15:TOTAL_%_LENOVO_PCS_TURNED-ON  -->												

												<!-- Q18:COMPETITOR_HARD_BRANDING  -->
												<template v-else-if="data.code ==='COMPETITOR_HARD_BRANDING'">												
													<br v-if="ii != 0 && subTasks.family === 'BRANDS'">
													<template v-if="subTasks.family">{{ subTasks.response }} </template>
													<template v-if="subTasks.family !== 'BRANDS' && subTasks.response && subTasks.family && isLastElement(tasksWithResponses.subTasks[ii+1])"> , </template>
													<template v-if="subTasks.family === 'BRANDS' && subTasks.response"> > </template>
													<small v-if="!subTasks.family && subTasks.response">({{ subTasks.response }})</small>
												</template>
												<!-- /Q18:COMPETITOR_HARD_BRANDING  -->


												<!-- OTHERS QUESTION -->
												<template v-else>

													<!-- Q17:POSM_PLACEMENT -->
													<template v-if="data.code ==='POSM_PLACEMENT'">
														<b-badge pill variant="light" class="badge-xs text-white ml-2">{{ subTasks.response }}</b-badge>
														<br>
													</template>
													<!-- /Q17:POSM_PLACEMENT -->

													<span v-if="subTasks.subType !== 'PRODUCT_SKU_DROPDOWN' && tasksWithResponses.type !== 'GROUP_PHOTO' && subTasks.response" 
														class="w-100 d-block"
													>
														<!-- Q8:LENOVO_FIXTURE_WEAR_&_TEAR [last updated on 06/09/22 : Custom text] -->
														<b v-if="data.code === 'LENOVO_FIXTURE_WEAR_&_TEAR'">Fixtures {{ ii+1 }} : {{ subTasks.response }}</b>
														<!-- /Q8:LENOVO_FIXTURE_WEAR_&_TEAR  -->
														
														<!-- Q19:COMPETITOR_PROMOTION  -->
														<b v-else-if="data.code === 'COMPETITOR_PROMOTION'">{{ getBrandIdx(tasksWithResponses.subTasks,subTasks.resId) }}</b>
														<!--/ Q19:COMPETITOR_PROMOTION  -->
														
														<!-- EXCEPT Q17 -->
														<b v-else-if="data.code !== 'POSM_PLACEMENT'">{{ subTasks.text }}</b>		
													</span>
													<!-- GROUP PHOTO  -->
													<template v-else-if="tasksWithResponses.type === 'GROUP_PHOTO' && subTasks.response">				
														<VisitationGroupPhoto :index="ii" :photo="subTasks" />
													</template>
													<!-- /GROUP PHOTO  -->												
	
													<!-- Sub-responsessss  -->
													<div v-if="tasksWithResponses.type !== 'GROUP_PHOTO'" class="w-100">
														<template v-if="data.code ==='COMPETITOR_PROMOTION' && subTasks.family === 'PROMOTION_TYPE'"><b>{{ subTasks.response }}</b></template>
														<template v-else-if="data.code !=='POSM_PLACEMENT' && data.code !== 'LENOVO_FIXTURE_WEAR_&_TEAR'">{{ subTasks.response }}</template>

														<template v-if="subTasks.subTasks && subTasks.subTasks.length > 0">													
															<span v-for="(subTasks2, iii) in subTasks.subTasks" :key="iii" 
																:class="[
																	{ 'responses clearfix': subTasks.type !== 'GROUP_PHOTO' && data.code !=='IDENTIFY_LENOVO_DEVICES_ON_DISPLAY' },
																	{'d-inline-block w-50': data.code ==='IDENTIFY_LENOVO_DEVICES_ON_DISPLAY'}
																	]"
																>
																<!-- GROUP PHOTO  -->
																<div v-if="subTasks.type === 'GROUP_PHOTO' && subTasks2.response" class="">
																	<VisitationGroupPhoto :index="iii" :photo="subTasks2" />
																</div>
																<!-- /GROUP PHOTO  -->
																<template v-else>
																	<span v-if="subTasks2.text && subTasks2.response & data.code !== 'COMPETITOR_PROMOTION'" class="w-100 d-block"><b>{{ subTasks2.text }}</b></span>
																	<span v-if="subTasks2.response" class="d-block w-100">
																		<!-- Q8:LENOVO_FIXTURE_WEAR_&_TEAR [last updated on 06/09/22 : Custom text] -->
																		<b v-if="data.code === 'LENOVO_FIXTURE_WEAR_&_TEAR'">Wear & Tear : {{ subTasks2.response }}</b>
																		<template v-else>{{ subTasks2.response }}</template>
																	</span>
																</template>

																<template v-if="subTasks2.subTasks && subTasks2.subTasks.length > 0">
																	<span v-for="(subTasks3, iv) in subTasks2.subTasks" :key="iv" :class="{ 'responses clearfix': subTasks2.type !== 'GROUP_PHOTO' && data.code !=='IDENTIFY_LENOVO_DEVICES_ON_DISPLAY' }">
																		<!-- GROUP PHOTO  -->
																		<div v-if="subTasks2.type === 'GROUP_PHOTO' && subTasks3.response" class="">
																			<VisitationGroupPhoto :index="iv" :photo="subTasks3" />
																		</div>
																		<!-- /GROUP PHOTO  -->
																		<template v-else>
																			<span v-if="subTasks3.text && subTasks3.response" class="w-100 d-block"><b>{{ subTasks3.text }}</b></span>
																			<span v-if="subTasks3.response" class="d-block w-100">{{ subTasks3.response }}</span>	
																		</template>

																		<template v-if="subTasks3.subTasks && subTasks3.subTasks.length > 0">
																			<span v-for="(subTasks4, v) in subTasks3.subTasks" :key="v" :class="{ 'responses clearfix': subTasks3.type !== 'GROUP_PHOTO' }">														
																				<!-- GROUP PHOTO  -->
																				<div v-if="subTasks3.type === 'GROUP_PHOTO' && subTasks4.response" class="">
																					<VisitationGroupPhoto :index="v" :photo="subTasks4" />
																				</div>
																				<!-- /GROUP PHOTO  -->
																				<template v-else>
																					<span v-if="subTasks4.text && subTasks4.response" class="w-100 d-block"><b>{{ subTasks4.text }}</b></span>
																					<span v-if="subTasks4.response" class="d-block w-100">{{ subTasks4.response }}</span>	
																				</template>

																				<template v-if="subTasks4.subTasks && subTasks4.subTasks.length > 0">
																					<span v-for="(subTasks5, v5) in subTasks4.subTasks" :key="v5" :class="{ 'responses clearfix': subTasks4.type !== 'GROUP_PHOTO' }">
																						<!-- GROUP PHOTO  -->
																						<div v-if="subTasks4.type === 'GROUP_PHOTO' && subTasks5.response" class="">
																							<VisitationGroupPhoto :index="v5" :photo="subTasks5" />
																						</div>
																						<!-- /GROUP PHOTO  -->
																						<template v-else>
																							<span v-if="subTasks5.text && subTasks5.response" class="w-100 d-block"><b>{{ subTasks5.text }}</b></span>
																							<span v-if="subTasks5.response" class="d-block w-100">{{ subTasks5.response }}</span>	
																						</template>
																					</span>
																				</template>

																			</span>
																		</template>
																	</span>
																</template>
															</span>														
														</template>
													</div>
													<!-- /Sub-response  -->
												</template>
												<!-- /OTHERS QUESTION -->
											</span>
										</template>
										<!-- /Sub-question  -->
									

									</div>
								</template>
								<div v-else class="w-100 response">	- </div>
							</li>
						</ul>
					</b-row>
				</div>
			</b-tab-item>
		</template>
	</b-tabs>
</template>

<script>
import { TASK_BY_VISITATION_ID_REQUEST } from "../../store/modules/outlet/actions";
import VisitationGroupPhoto from "./visit_group_photo.vue"
import cloneDeep from "lodash/cloneDeep";

export default {
	name: "VisitationTasks",
	components: {
		VisitationGroupPhoto
	},
	filters: {},
	props: {
		getListByVisitId: { type: Function, default: () => { } },
	},
	computed: {},
	data() {
		return {
			isLoading: false,
			activeTab: 0,
			taskList: [],
			details: {},
			totalGen: 0,
			totalCompetitor: 0
		};
	},
	watch: {},
	methods: {
		getVisitationTask(id) {
			if (this.taskList.length === 0) {
				this.isLoading = true;
				this.$store.dispatch(TASK_BY_VISITATION_ID_REQUEST, id).then((res) => {
					if (res.content && res.resultCode === 0) {
						this.details = cloneDeep(res.content);
						this.taskList = cloneDeep(res.content.tasks);
						this.isLoading = false;
					}
				});
			}
		},
		getFixturePercentage(data) {
			let fixtures = parseInt(data)
			let q8 = [] 
			
			this.taskList.forEach(task=>{
				if(task.code==='LENOVO_AMBIENCE'){
					task.subSections.forEach(q=>{
						if(q.code === "LENOVO_FIXTURE_WEAR_&_TEAR") {
							q8 = cloneDeep(q.tasksWithResponses[0].subTasks)
						}
					})
				}
			})

			const q8Count = q8[0].response ? q8.length : 0
			return Math.round( (q8Count / fixtures) * 100) || 0
		},
		getFixtureKeyVisualPercentage(data) {
			let q2a = parseInt(data)
			let q8 = 0
			let q2b = 0
			let q2c = 0

			this.taskList.forEach((task) => {
				if (task.code === 'LENOVO_AMBIENCE') {
					task.subSections.forEach((q) => {
						if (q.code === "LENOVO_FIXTURE_WEAR_&_TEAR") {
							if (q.tasksWithResponses[0] && q.tasksWithResponses[0].subTasks) {
								const subTasks = q.tasksWithResponses[0].subTasks
								q8 = subTasks[0].response ? subTasks.length : 0
							}
						}
					})
				}
				if (task.code === "ONE_TIME_SCORING") {
					task.subSections.forEach((q) => {
						if (q.code === "LENOVO_FIXTURE_SHARE_OF_SPACE") {
							if (q.tasksWithResponses) {
								q.tasksWithResponses.forEach((q2) => {
									if (q2.id === 421) {
										q2b = parseInt(q2.response)
									}
									if (q2.id === 434) {
										q2c = parseInt(q2.response)
									}
								})
							}
						}
					})
				}
			})

			const denominator = q2a + q2b + q2c
			if (denominator === 0) return 0
			return Math.round( ((q8 + q2b) / denominator) * 100) || 0
		},
		getTotalPOSM(data) {
			const res = data.filter(el => {
				return el.subTasks[0].response === 'Yes'
			})
			return res ? res.length : 0
		},
		getTotalGen(newGen,oldGen) {
			const totalNewGen = newGen ? this.getTotalGenModelAmount(newGen) : 0
			const totalOldGen = oldGen ? this.getTotalGenModelAmount(oldGen) : 0
			this.totalGen = totalNewGen+totalOldGen
			return this.totalGen
		},
		getTotalDemoUnit() {
			const total = this.totalCompetitor + this.totalGen
			return total || 0
		},
		getTotalAccessoryAmount(list) {
			// NOTE: id = 10 (number); id = 11 (product): id = 12 (others)
			let total = 0
			list.forEach(item => {
				if(item.id === 11 && item.response) total += 1
			});
			return total || 0
		},
		getDemoUnitPercentage(unit,gen) {
			// NOTE: (3a + 3b) / (3a + 3b + Q5) * 100		
			const newGen = gen[0] ? gen[0].subTasks : 0
			const oldGen = gen[1] ? gen[1].subTasks : 0

			const totalNewGen = newGen ? this.getTotalGenModelAmount(newGen) : 0
			const totalOldGen = oldGen ? this.getTotalGenModelAmount(oldGen) : 0

			const totalGen = totalNewGen + totalOldGen

			return Math.round((totalGen / (totalGen + parseInt(unit))) * 100) || 0
		},
		getCompetitorAmount(list) {
			// NOTE: id = 349 (number); id = 18 (brand); id = 19 (On/Off)
			let total = 0
			list.forEach(item => {
				if(item.id === 349) total += parseInt(item.response)
			});

			this.totalCompetitor = cloneDeep(total)
			return total || 0
		},
		getOtherDevicesCount(list) {
			const ll = list.subTasks
			return ll[0].response ? ll.length : 0
		},
		getTotalGenModelAmount(list) {
			// id 4 = new gen. model ; id 6 = old gen. model
			let total = 0
			if(list) {
				list.forEach(item => {
					if(item.type === "NUMBER") total += parseInt(item.response)
				});
			}
			return total || 0
		},
		getBrandIdx(list,id) {
			// Only show brand list index, here to exclude all promo from the list
			const brandList = list.filter(function (el) {
				return el.family === "BRANDS"
			});
			const index = brandList.findIndex((x) => x.resId === id);

			return index>= 0 ? 'Competitor ' + (index+1) : ''
		},
		isLastElement(list) {
			return !!(list && list.family !== 'BRANDS')
		},
		haveNoResponse(list) {
			if(!list.response & (list.subTasks.length === 0 || !list.subTasks[0].response)) return true
		}
	},
	mounted() { },
};
</script>

<style lang="scss">
#visit-task-tabs {
	width: 100%;

	.tabs {
		ul {
			margin-left: 0;

			li {
				cursor: pointer;
			}

			li.is-active {
				a {
					font-weight: 600;
					color: #f70301;

					:hover {
						color: #f70301;
					}
				}
			}
		}
	}

	ul.task {
		margin: 0 1em;
		overflow-y: scroll;
		max-height: 300px;
		width: 100%;

		// Unhide scrollbar : 06/09/22
		// /* Hide scrollbar for IE, Edge and Firefox */
		// -ms-overflow-style: none;
		// scrollbar-width: none;

		// /* Hide scrollbar for Chrome, Safari and Opera */
		// &::-webkit-scrollbar {
		// 	display: none;
		// }

		li.question {
			display: block;
			margin-bottom: 3rem;

			div.response {
				font-size: 12px;
				line-height: 2.6rem;
				padding-left: 25px;
				border-bottom: 1px solid #d0d0d0;
			}
			.responses-nl{
				font-size: 12px;
				line-height: 2.6rem;
				padding-left: 25px;
			}
			.responses-nl:last-of-type {
				border-bottom: 1px solid #d0d0d0;
			}

			.special-res {
				padding-left: 25px;
				margin: 1rem 0;
				display: flow-root;
				border-bottom: 1px solid #d0d0d0;
			}
		}

		.responses:not(:last-of-type) {
				border-bottom: 1px solid #f3f3f3;
				display: block;
		}

		.responses { border-bottom: 1px solid #eceaea; }

		.task-number {
			background: #ebebeb;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			display: inline-block;
			text-align: center;
			font-size: 11px;
			line-height: 20px;
			margin-right: 5px;
		}

		.sub-question-title {
			line-height: 1.75rem;
			padding: 0.75rem 0;
			border-bottom: 1px dotted #dedede;
		}
	}
}
</style>
