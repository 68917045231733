<template>
  <VueBootstrap4Table
    :classes="classes"
    :rows="productCategoryList"
    :columns="columns"
    :loading="isDataLoading"
    :config="config"
    :total-rows="total"
    :show-loader="true"
  >
    <template slot="sort-asc-icon">
      <i class="mdi mdi-sort-ascending"></i>
    </template>
    <template slot="sort-desc-icon">
      <i class="mdi mdi-sort-descending"></i>
    </template>
    <template slot="no-sort-icon">
      <i class="mdi mdi-sort-variant"></i>
    </template>
    <template slot="empty-results">
      <div
        v-if="!isDataLoading"
        class="bold-text"
      >No Product Groups Found!</div>
      <div
        v-if="isDataLoading"
        class="loading mt-3"
      ></div>
    </template>
  </VueBootstrap4Table>
</template>
<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST } from "../../store/modules/incentive/action";
export default {
  name: "IncentiveShowModal",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  props: {
    productShow: {type: Object, default: () => {}},
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "productGroup",
          label: "Product Group",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "productCategory",
          label: "Category",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      productCategoryList: [],
      countryId: this.productShow.id,
      isDataLoading: false
    }
  }, 
  methods: {
    getProductGroupList(){
      this.isDataLoading = true;
      this.$store.dispatch(INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST, this.countryId).then(res => {
        if(res.content && res.resultCode === 0){
          this.productCategoryList = 
          this.productShow.productCategoryList.map(el => 
          { return {
            productCategory: el, 
            productGroup: res.content.find(item => item.productCategoryList.find(element => element.productCategory === el)).productGroup
          }})
          this.isDataLoading = false;
        }
      });
    }
  },
  mounted(){
    if(this.productShow.productCategoryList){
      this.getProductGroupList();
    } else {
      this.productCategoryList = [];
    }
  }
}
</script>