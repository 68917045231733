// Auth Services
export const API_LOGIN_SERVICE = "authenticate";
export const API_LOGOUT_SERVICE = "app-account/logout";
export const API_FORGOT_PW_SERVICE = "signup/reset-password-web";
export const API_USER_PROFILE = "user-profiles/web";
export const API_UPDATE_USER_PROFILE = "user-profiles/web/update";
export const API_UPDATE_USER_PASSWORD = "user-profiles/web/update";

// Admin Services
export const API_ADMIN_CLEAN_CACHE = "cache/clean";
export const API_ADMIN_SERIAL_REMATCH = "sales/serial-rematch";
export const API_ADMIN_REGISTRATION = "signup/admin";
export const API_SALES_PERSON_REGISTRATION = "signup/sales-person-register";
export const API_SALESPERSON_CHECK = "signup/sales-person-check";
export const API_REGIONAL_ADMIN_REGISTRATION = "signup/regional/admin";
export const API_REGIONAL_REGISTRATION = "signup/regional";
export const API_COUNTRY_HEAD_REGISTRATION = "signup/country";
export const API_DEALER_REGISTRATION = "signup/dealer";
export const API_PROMOTER_LEADER_REGISTRATION = "signup/promoter-leader";
export const API_PROMOTER_CHECK_REQUEST = "signup/promoter-check";
export const API_PROMOTER_REGISTRATION = "signup/promoter-register";
export const API_RESET_PASSWORD = "signup/reset-password";
export const API_RESET_PASSWORD_WEB = "signup/reset-password-web";
export const API_AGENCY_REGISTRATION = "signup/agency";
export const API_CHECKER_REGISTRATION = "signup/checker";

// Regional Dashboard Services
export const API_DASHBOARD_TOP_ROW_DATA_SERVICE = "dashboard/toprowdata";
export const API_DASHBOARD_TOP_FILTERS_SERVICE = "dashboard/regional/dropdowns";
export const API_DASHBOARD_PRODUCT_FILTERS_SERVICE = "dashboard/regional/dropdowns/product-performance/category";
export const API_DASHBOARD_COMPONENT_FILTERS_SERVICE = "dashboard/regional/dropdowns/product-performance/component";
export const API_REGIONAL_DASHBOARD_DATA_SERVICE = "dashboard/regional/charts";
export const API_REGIONAL_DASHBOARD_DATA_SERVICE_V2 = "dashboard/v2/regional";

// Country Dashboard Service
export const API_COUNTRY_DASHBOARD_FILTERS_SERVICE = "dashboard/country/dropdowns";
export const API_COUNTRY_DASHBOARD_PRODUCT_FILTERS_SERVICE = "dashboard/country/dropdowns/product-performance/category";
export const API_COUNTRY_DASHBOARD_COMPONENT_FILTERS_SERVICE = "dashboard/country/dropdowns/product-performance/component";
export const API_COUNTRY_DASHBOARD_DATA_SERVICE = "dashboard/country/charts";

// Merchandiser Dashboard Service
export const API_MERCHANDISER_DASHBOARD_VISITATION = "merchandiser/dashboard/charts/visitation/";
export const API_MERCHANDISER_DASHBOARD_SCORE_GRADE = "merchandiser/dashboard/charts/score-grade/";
export const API_MERCHANDISER_DASHBOARD_STORE_VS_CATEGORY = "merchandiser/dashboard/charts/store-vs-category/";
export const API_MERCHANDISER_DASHBOARD_VISITATION_SUB_REGION = "merchandiser/dashboard/charts/visitation-sub-region/";
export const API_MERCHANDISER_DASHBOARD_SCORE_GRADE_SUB_REGION = "merchandiser/dashboard/charts/score-grade-sub-region/";
export const API_MERCHANDISER_DASHBOARD_SCORE_GRADE_BY_TOP_DEALER = "merchandiser/dashboard/charts/score-grade-by-top-dealer/";
export const API_MERCHANDISER_DASHBOARD_LENOVO_VS_COMPETITOR = "merchandiser/dashboard/charts/lenovo-vs-competitor/";

// User Services
export const API_SINGLE_USER = "user-profiles/web";

// Incentive Services
export const API_INCENTIVE_NORMAL_LIST_SERVICE = "incentive-normal/list/";
export const API_INCENTIVE_NORMAL_LIST_ADD_SERVICE = "incentive-normal/list/add";
export const API_INCENTIVE_NORMAL_LIST_UPDATE_SERVICE = "incentive-normal/list/update";
export const API_INCENTIVE_NORMAL_GET_BY_ID = "incentive-normal/incentives-with-dealer-outlets/";
export const API_INCENTIVE_NORMAL_ALL_SERVICE = "/incentive-normal/list/all/";
export const API_GENERATE_INCENTIVE_PAYABLE_SERVIVE = "payout/generate";
export const API_INCENTIVE_NORMAL_GET_ALL_MTM = "incentive-normal/list/mtm";
export const API_GET_PAYOUT_BY_YEAR_SERVICE = "payout/list/";

// Generate Promoter Incentive Payable
export const API_PROMOTER_PAYOUT_BY_YEAR_SERVICE = "payout/list-promoter/";
export const API_PROMOTER_PAYOUT_BY_YEAR_SERVICE_COUNTRY = "payout/countries/";
export const API_GENERATE_PROMOTER_INCENTIVE_PAYABLE_SERVICE = "payout/generate-promoter";
export const API_UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID = "payout/update-payable-paid";

// Incentive Bonus Services
export const API_INCENTIVE_BONUS_LIST_SERVICE = "incentive-bonus/list/";
export const API_INCENTIVE_BONUS_UTILITY_GET_SERVICE = "incentive-bonus/utility"; // "utility/incentive-bonus";
export const API_INCENTIVE_BONUS_ADD = "incentive-bonus/add-by-mtm";
export const API_INCENTIVE_BONUS_UPDATE = "incentive-bonus/update-by-mtm";
export const API_INCENTIVE_BONUS_GET_BY_ID = "incentive-bonus/";

// Incentive Target Services
export const API_INCENTIVE_TARGET_LIST_SERVICE = "incentive-target/list/";
export const API_INCENTIVE_TARGET_ADD = "incentive-target/add-by-mtm";
export const API_INCENTIVE_TARGET_UPDATE = "incentive-target/update-by-mtm";
export const API_INCENTIVE_TARGET_GET_BY_ID = "incentive-target/";
export const API_INCENTIVE_TARGET_UTILITY_GET_SERVICE = "incentive-target/utility";

// Incentive Promoter Services
export const API_INCENTIVE_PROMOTER_LIST_SERVICE = "incentive-promoter/list";
export const API_INCENTIVE_PROMOTER_ADD = "incentive-promoter/add";
export const API_INCENTIVE_PROMOTER_UPDATE = "incentive-promoter/update";
export const API_INCENTIVE_PROMOTER_GET_BY_ID = "incentive-promoter/list/";
export const API_INCENTIVE_PROMOTER_PRODUCT_FAMILY = "incentive-promoter/product-family";
export const API_INCENTIVE_PROMOTER_QUERY_MTM = "incentive-promoter/query-mtm";
export const API_INCENTIVE_PROMOTER_CHECK_MTM = "incentive-promoter/check-mtm";

// Incentive Salesperson Services
export const API_INCENTIVE_NORMAL_LIST_SERVICE_NEW_SALESPERSON = "incentive-new-salesperson/list";
export const ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM = "incentive-new-salesperson/add";
export const API_INCENTIVE_DETAILS_BY_ID = "incentive-new-salesperson/details";
export const UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM = "incentive-new-salesperson/update";
export const API_INCENTIVE_NEW_SALESPERSON_FILE_CHECK = "incentive-new-salesperson/file-check";
export const API_INCENTIVE_NEW_SALESPERSON_TEMPLATE = "incentive-new-salesperson/template-url";

// Dealer Incentive Entitlement Services
export const API_DEALER_INC_ENT_LIST_SERVICE = "dealer-incentive-entitlement/list";
export const API_DEALER_INC_RECEIPT_LIST_SERVICE = "dealer-incentive-entitlement/list-receipt-upload/";
export const API_DEALER_INC_ENT_UPLOAD_BY_DEALER_SERVICE = "dealer-incentive-entitlement/upload-by-dealer";
export const API_DEALER_INC_ENT_UPLOAD_BY_MONTH_SERVICE = "dealer-incentive-entitlement/upload-by-month-year";
export const API_DEALER_SELL_THRU_REQUEST = "sell-thru/dealers/all";
export const API_EXPORT_DEALER_SELL_THRU_REQUEST = "sell-thru/dealers/all/export";
export const API_DEALER_SELL_THRU_LATEST_WEEKS = "sell-thru/dealers/latest/week";

// Incentive  Normal Promoter Services
export const API_INC_NORMAL_PROMOTER_LIST_SERVICE = "incentive-new-promoter/list";
export const API_INC_NORMAL_PROMOTER_LIST_ADD_SERVICE ="incentive-normal-promoter/list/add";
export const API_INC_NORMAL_PROMOTER_LIST_UPDATE_SERVICE ="incentive-normal-promoter/list/update";
export const API_INC_NORMAL_PROMOTER_GET_BY_ID = "incentive-normal-promoter/incentives-with-dealer-outlets/";
export const API_INC_NORMAL_PROMOTER_GET_ALL_MTM = "incentive-normal-promoter/list/mtm";
export const API_INC_PROMOTER_PRODUCT_GROUP_LIST = "incentive-new-promoter/product-group";
export const API_EDIT_INCENTIVE = "incentive-new-promoter/update";
export const API_INCENTIVE_PRODUCT_CATEGORY_BY_PRICE = "incentive-new-promoter/product-category-by-price";
export const API_INCENTIVE_NEW_PROMOTER_CHECK_MTM = "incentive-new-promoter/check-model-mtm";
export const API_INCENTIVE_NEW_PROMOTER_ADD = "incentive-new-promoter/add";

// Dealer Services
export const API_DEALER_LIST_SERVICE = "dealer-info/viewall/pic"; //'dealer-outlets/viewall';
export const API_DEALER_LIST_WITH_PARAMS_SERVICE = "dealers/all";
export const API_DEALER_LIST_WITH_FILTERS = "dealers/v2";
export const API_ALL_DEALER_LIST_SERVICE = "dealer-info/viewall";
export const API_SINGLE_DEALER_SERVICE = "dealer-info/view/";
export const ADD_DEALER_SERVICE = "dealer-info/add";
export const EXPORT_DEALER_SERVICE = "";
export const UPDATE_DEALER_SERVICE = "dealer-info/update";
export const SUBMIT_DEALER_INCENTIVE_SERVICE = "dealer-info/grp/update";
export const API_DEALERS_OUTLETS_LIST_SERVICE = "dealer-info/viewall/outlets";
export const API_DEALER_SELL_IN_LIST_SERVICE = "sell-in/list";
export const API_DEALER_SELL_IN_ADD_SERVICE = "sell-in/create";
export const API_DEALER_SELL_OUT_LIST_SERVICE = "sell-out/list";
export const API_DEALER_SELL_OUT_ADD_SERVICE = "sell-out/create";
export const API_DEALER_STOCK_LIST_SERVICE = "dealer-stock/list-by-dealer";
export const API_DEALER_STOCK_LIST_MONTHLY_SERVICE = "dealer-stock/list-monthly";
export const API_DEALER_STOCK_LIST_DOWNLOAD_SERVICE = "dealer-stock/download";
export const API_GET_SELL_THRU_UPLOADS_ALL = "sell-thru/dealers/uploads/all";
export const API_DEALER_SELL_THRU_UPLOAD_REQUEST = "sell-thru/dealers/uploads";

// Bulk Sales Services

export const API_SEND_EMAIL_REQUEST = "sales-transactions/send-to-email";
export const API_EXPORT_SALES_DATA = "sales-transactions/export";
export const API_VALIDATE_UNKNOWN_SERIAL_NUMBER = "sales/validate/web";

// Outlet Services
export const API_PIC_LIST_BY_DEALER_SERVICE = "outlet-pic/view-by-dealer";
export const API_OUTLET_PIC_LIST_SERVICE = "outlet-pic/viewall/outlets";
export const API_SINGLE_OUTLET_PIC_SERVICE = "outlet-pic/view/";
export const ADD_OUTLET_PIC_SERVICE = "outlet-pic/add";
export const EXPORT_OUTLET_PIC_SERVICE = "";
export const UPDATE_OUTLET_PIC_SERVICE = "outlet-pic/update";

// Dealer Outlet Services
export const API_DEALER_OUTLET_LIST_SERVICE = "dealer-outlets/all-by-region";
export const API_OUTLET_LIST_SERVICE = "dealer-outlets/viewall"; // 'outlet-pic/viewall';
export const GET_DEALERS_OUTLETS_LIST_SERVICE = "dealer-outlets";

export const API_SINGLE_OUTLET_SERVICE = "dealer-outlets/view/";
export const ADD_OUTLET_SERVICE = "dealer-outlets/add";
export const EXPORT_OUTLET_SERVICE = "";
export const UPDATE_OUTLET_SERVICE = "dealer-outlets/update";
export const UPLOAD_BULK_OUTLET_SERVICE = "dealer-outlets/upload";
export const API_OUTLET_WITH_PIC_LIST_SERVICE = "dealer-outlets/viewall";
export const API_GET_ALL_DEALER_OUTLETS_BY_REGION = "dealer-outlets/all-by-region-state-dealer";
export const GET_MERCHANDISER_VISITATION_BY_OUTLET_ID = "/dealer-outlets/merchandiser-visitation-details";
export const GET_TASK_BY_VISITATION_ID = "/merchandiser/task/visitation/responses/";

export const API_GET_OUTLET_CHANGES = "approval/get-outlet-change";
export const API_GET_DEALER_CHANGES = "approval/get-dealer-change";

// Staff Services
export const PENDING_SALESPERSON_LIST_SERVICE = "approval/all";
export const APPROVE_REJECT_SALESPERSON_SERVICE = "approval/update";
export const GET_PROMOTER_LEADERS_SERVICE = "staff-list/promoter-leader/all";
export const GET_PROMOTER_LIST_SERVICE = "staff-list/promoters/template";
export const GET_SALESPERSON_LIST_SERVICE = "staff-list/salesperson/all";
export const GET_MERCHANDISER_LIST_SERVICE = "staff-list/merchandiser/all";
export const GET_MERCHANDISER_BY_LEADER_ID = "/staff-list/merchandiser/by-merchandiser-leader/";
export const GET_MERCHANDISER_LEADER_LIST_SERVICE = "staff-list/merchandiser-leader/all";
export const ADD_PROMOTER_LEADER_SERVICE = "staff-list/promoter-leader/add";
export const GET_LIST_SALESPERSON_SERVICE = "staff-list/salespersons";
export const ADD_PROMOTER_SERVICE = "staff-list/promoter/add";
export const ADD_SALESPERSON_SERVICE = "staff-list/salesperson/add";
export const UPDATE_SALESPERSON_SERVICE = "staff-list/salesperson/update";
export const UPDATE_PROMOTER_SERVICE = "staff-list/promoter/update";
export const UPDATE_PROMOTER_LEADER_SERVICE = "staff-list/promoter-leader/update";
export const GET_PROMOTERS_WITH_OUTLETS_SERVICE = "staff-list/promoter/all/with-outlets";
export const GET_PROMOTERS_LIST_BY_PROMOTER_SUPERVISOR_ID = "staff-list/supervisors";
export const GET_PROMOTERS_LIST_SERVICE = "staff-list/promoters";
export const GET_PROMOTERS_SUPERVISOR_LIST_SERVICE = "staff-list/promoter-supervisors";
export const GET_ACTIVE_PROMOTERS_WITH_OUTLETS_SERVICE = "staff-list/promoters/active/with-outlets";
export const ASSIGN_PROMOTER_OUTLET_SERVICE = "staff-list/promoters/assign-outlet";
export const UNASSIGN_PROMOTER_OUTLET_SERVICE = "staff-list/promoter/unassign-outlet";
export const EDIT_PROMOTER_OUTLET_SERVICE = "staff-list/promoter/edit-assigned-period";
export const ADD_STATE_MANAGER_SERVICE = "manager/state/create";
export const UPDATE_STATE_MANAGER_SERVICE = "manager/state/update";
export const GET_STATE_MANAGER_LIST_SERVICE = "manager/state/list"
export const GET_REGIONAL_MANAGER_LIST_SERVICE = "manager/regional/list";
export const ADD_REGIONAL_MANAGER_SERVICE = "manager/regional/create";
export const UPDATE_REGIONAL_MANAGER_SERVICE = "manager/regional/update";
export const API_GET_PENDING_DEALER_LIST = "approval/all/dealers";
export const API_GET_PENDING_PROMOTER_LEADER_LIST = "approval/all/promoters-leader";
export const API_GET_PENDING_DEALER_OUTLET_LIST = "approval/all/dealer-outlets";
export const API_GET_PENDING_PROMOTER_LIST = "approval/all/promoters";
export const API_GET_PENDING_MERCHANDISER_LIST = "approval/all/merchandisers";
export const API_GET_PENDING_MERCHANDISER_LEADER_LIST = "approval/all/merchandiser-leader";
export const API_UPDATE_APPROVAL_PROMOTER = "approval/update";
export const API_DEALER_PENDING_APPROVAL_FLAG = "approval/dealer";
export const API_DEALER_OUTLET_PENDING_APPROVAL_FLAG = "approval/dealer-outlet";
export const API_PROMOTER_PENDING_APPROVAL_FLAG = "approval/promoter";
export const API_PROMOTER_LEADER_PENDING_APPROVAL_FLAG = "approval/promoter-leader";
export const API_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG = "approval/promoter-outlet-assignment";
export const API_UPDATE_APPROVAL_DEALER_OUTLET = "approval/update-dealer-outlet";
export const API_UPDATE_APPROVAL_DEALER = "approval/update-dealer";
export const API_UPDATE_APPROVAL_MERCHANDISER = "approval/update-merchandiser";
export const API_UPDATE_APPROVAL_MERCHANDISER_LEADER = "approval/update-merchandiser-leader";
export const API_UPDATE_APPROVAL_PROM = "approval/update-promoter";
export const UPDATE_MERCHANDISER_SERVICE = "staff-list/merchandiser/update";
export const UPDATE_MERCHANDISER_LEADER_SERVICE = "staff-list/merchandiser-leader/update";
export const ADD_MERCHANDISER_SERVICE = "staff-list/merchandiser/add";
export const ADD_MERCHANDISER_LEADER_SERVICE = "staff-list/merchandiser-leader/add";
export const API_MERCHANDISER_PENDING_APPROVAL_FLAG = "approval/merchandiser";
export const API_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG = "approval/merchandiser-leader";


// Product Services
export const API_PRODUCT_LIST_SERVICE = "product-info/viewall/web";
export const API_SINGLE_PRODUCT_SERVICE = "product-info/view/";
export const DELETE_PRODUCT_SERVICE = "product-info/delete";
export const ADD_PRODUCT_SERVICE = "product-info/add";
export const ADD_PRODUCT_BULK_SERVICE = "product-info/add/bulk";
export const UPDATE_PRODUCT_SERVICE = "product-info/update";
export const UPLOAD_PRODUCT_GUILD_SERVICE = "poduct-info/upload-guide";
export const API_PRODUCT_SKU_LIST_SERVICE = "product-sku/all-with-pricing";
export const API_PRODUCT_SKU_UPDATE_SRP = "product-sku/update-srp-by-mtm";
export const UPLOAD_PRODUCT_SKU_LIST = "product-sku/upload-pricings";
export const API_FILE_CHECK_PRODUCT_SKU_LIST_UPLOAD = "product-sku/pricing/file-check";
export const API_ADD_PRODUCT_SKU_LIST_UPLOAD = "product-sku/update-pricing";
// Report Services

// Common Services

// News Services
export const API_NEWS_LIST_SERVICE = "news/all";
export const API_SINGLE_NEWS_SERVICE = "/api/news/";
export const DELETE_NEWS_SERVICE = "/news/delete/";
export const ADD_NEWS_SERVICE = "news/add";
export const BULK_ADD_NEWS_SERVICE = "news/add/bulk";
export const UPLOAD_NEWS_IMAGE_SERVICE = "news/uploads";
export const UPDATE_NEWS_SERVICE = "news/update";

// Utility Services
export const GET_BANK_LIST_SERVICE = "utility/banks/";
export const GET_STATE_LIST_SERVICE = "utility/states/all";
export const GET_CATEGORY_LIST_SERVICE = "utility/outlet/category";
export const GET_COUNTRY_LIST_SERVICE = "utility/countries";
export const GET_COUNTRIES_WITH_REGION_SERVICE = "utility/countries-region";
export const GET_GENDER_LIST_SERVICE = "utility/genders";
export const GET_NEWS_TARGETS_SERVICE = "utility/news-target";
export const GET_PRODUCT_TYPES_SERVICE = "utility/product-type";
export const GET_PRODUCT_GROUPS_SERVICE = "utility/product-group";
export const GET_SALE_STATUS_SERVICE = "utility/sales-status-web";
export const GET_SERIAL_TYPE_SERVICE = "utility/serial-type-web";
export const GET_CHECK_STATUS_SERVICE = "utility/check-status";
export const GET_FAIL_REASONS_SERVICE = "utility/failed-reason";
export const GET_INCENTIVE_TARGET_TYPES_SERVICE = "utility/incentive-generation-type";
export const GET_TARGET_SALES_TYPES_SERVICE = "utility/target-sales-type";
export const GET_PROMOTER_TYPES_SERVICE = "utility/promoter-type";
export const GET_STORE_TYPES_SERVICE = "utility/store-type";
export const GET_ACCREDITATION_SERVICE = "utility/accreditation";
export const GET_FOCUS_PRODUCT_SERVICE = "utility/focus-product";
export const GET_STORE_IDENTITY_SERVICE = "utility/store-identity";
export const GET_REGIONS_SUBREGIONS_SERVICE = "utility/all-regions-subregions";
export const API_UPLOAD_FILE_SERVICE = "utility/save-file";
export const GET_DASHBOARD_SELECTED_FISCAL_YEAR_AND_QUARTER = "utility/get/default-selected-option";

// Message Services
export const ADD_MESSAGE_TREAD_SERVICE = "private-message/thread/add";
export const GET_MESSAGES_TREAD_BY_USER_SERVICE = "private-message/thread/list";
export const GET_MESSAGES_TREAD_BY_COUNTRY_SERVICE = "private-message/thread/list/country";
export const SEND_MESSAGE_BY_TREAD_SERVICE = "private-message/reply";
export const GET_MESSAGES_TREAD_SERVICE = "private-message/list";
export const READ_MESSAGE_SERVICE = "private-message/read/web";
export const GET_UNREAD_MESSAGE_SERVICE = "private-message/unread-count";

// Bulk Sales
export const UPLOAD_BULK_SALE_SERVICE = "sales/add/web";
export const GET_BULK_SALES_SERVICE = "sales/get/sales-upload";
export const DOWNLOAD_BULK_SALES_SERVICE = "sales/get/sales-upload/detail/";
export const GET_UNKNOWN_SERIAL_LIST_SERVICE = "sales/get/web";
export const UPDATE_SALE_DATA_SERVICE = "sales/update/web";
export const CHECK_SERIAL_NUMBER_SERVICE = "product-serial/check-serial-web/";

// Exclusive MTM
export const GET_EXCLUSIVE_MTM_SERVICE = "exclusive-mtm/list";
export const UPLOAD_EXCLUSIVE_MTM_SERVICE = "exclusive-mtm/create";
export const UPDATE_EXCLUSIVE_MTM_SERVICE = "exclusive-mtm/update";

// Target Service
export const GET_SALES_DATA_SERVICE = "sales/get/web";
export const UPDATE_SALES_DATA_SERVICE = "sales/update/web";
export const DOWNLOAD_SALES_DATA_SERVICE = "sales-transactions/all";
export const DOWNLOAD_INCENTIVE_PAYABLE_SERVICE = "payout/download";
export const API_EXPORT_BMS = "sales-transactions/export-retail-bms";

// Target Country Services
export const GET_TARGET_COUNTRY_BY_YEAR_SERVICE = "target/country/all/";
export const ADD_TARGET_COUNTRY_BY_YEAR_SERVICE = "target/country/add";
export const ADD_TARGET_COUNTRY_BY_WEEK_SERVICE = "target/country/weekly/update"; // "target/country/weekly/add";
export const GET_TARGET_COUNTRY_BY_WEEK_SERVICE = "target/country/weekly/fiscal/";
export const GET_TARGET_PROMOTER_BY_YEAR_SERVICE = "target/promoter/all/";
export const ADD_TARGET_PROMOTER_BY_YEAR_SERVICE = "target/promoter/add";
export const ADD_TARGET_PROMOTER_BY_WEEK_SERVICE = "target/promoter/weekly/update";
export const GET_TARGET_PROMOTER_BY_WEEK_SERVICE = "target/promoter/weekly/fiscal/";
export const UPLOAD_TARGET_PROMOTER_BY_REGION = "target/promoter/weekly/file-check";
export const ADD_TARGET_PROMOTER_BY_ID = "target/promoter/weekly/update-by-id";

// Target Promoter Supervisor Services
export const GET_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE = "target/promoter-leader/weekly/fiscal/";
export const ADD_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE = "target/promoter-leader/weekly/update";
export const UPLOAD_TARGET_PROMOTER_LEADER_BY_REGION = "target/promoter-leader/weekly/file-check";
export const ADD_TARGET_PROMOTER_LEADER_BY_ID = "target/promoter-leader/weekly/update-by-id";

// Reports
export const PROMOTER_ATTENDANCE_DATE_SERVICE = "promo-atten-info/dates";
export const DOWNLOAD_PROMOTER_ATTENDANCE_SERVICE = "promo-atten-info/get/web/download";
export const DOWNLOAD_NORMAL_INC_ENT_SERVICE = "";
export const DOWNLOAD_BONUS_INC_ENT_SERVICE = "";
export const DOWNLOAD_TARGET_INC_ENT_SERVICE = "incentive-target/payable/all";
export const DOWNLOAD_DEALER_INC_ENT_SERVICE = "";

// Merchandiser Visitation
export const SEND_VISITATION_SERVICE = "/merchandiser/visitation/send-to-email";
export const DOWNLOAD_VISITATION_SERVICE = "/merchandiser/visitation/export-details";
export const DOWNLOAD_VISITATION_SUMMARY_SERVICE = "/merchandiser/visitation/export-visitation-summary"
export const DOWNLOAD_VISITATION_DASHBOARD_SERVICE = "/merchandiser/visitation/export-visitation-dashboard"
export const SEND_VISITATION_SUMMARY_SERVICE = "/merchandiser/visitation/send-to-email/visitation-summary"
export const SEND_WEARTEAR_SERVICE = "/merchandiser/visitation/send-to-email/wear-and-tear";
export const DOWNLOAD_WEARTEAR_SERVICE = "/merchandiser/visitation/export-wear-and-tear-details";
export const WEEKLY_VISITATION_SERVICE = "/merchandiser/visitation/weekly-list";
export const DOWNLOAD_WEEKLY_VISITATION_SERVICE = "/merchandiser/visitation/export-schedule";
export const UPLOAD_WEEKLY_VISITATION_SERVICE = "/merchandiser/visitation/upload";
export const DOWNLOAD_VISITATION_TEMPLATE_SERVICE = "/merchandiser/visitation/export-schedule-template";
export const VALIDATE_VISITATION_SERVICE = "/merchandiser/visitation/file-check";

// Distributor Services
export const API_DISTRIBUTOR_LIST_SERVICE = "distributor/get";
export const API_SINGLE_DISTRIBUTOR_SERVICE = "distributor/view/";
export const ADD_DISTRIBUTOR_SERVICE = "distributor/add";
export const UPDATE_DISTRIBUTOR_SERVICE = "distributor/update";

// Settings
export const API_GET_MOBILE_APP_SETTINGS = "app-setting/get/";
export const API_UPDATE_MOBILE_APP_SETTINGS = "app-setting/update";

// Lock Serial Number
export const GET_LOCK_SERIAL_SERVICE = "lock-serial/list";
export const ADD_LOCK_SERIAL_SERVICE = "lock-serial/create";
export const UPDATE_LOCK_SERIAL_SERVICE = "lock-serial/update";
export const CANCEL_LOCK_SERIAL_SERVICE = "lock-serial/cancel";
export const DOWNLOAD_LOCK_SERIAL_SERVICE = "lock-serial/download";

// Sub Region Target Services
export const GET_SUB_REGION_SERVICE = "target/subregions/list";
export const GET_SUB_REGION_TARGET_SERVICE = "target/subregions/";
export const UPLOAD_SUB_REGION_TARGET_BY_REGION = "target/subregions/file-check";
export const ADD_SUB_REGION_TARGET_BY_ID = "target/subregions/update-by-id";

// Dealer Target Services
export const GET_DEALER_SERVICE = "target/dealers/list";
export const GET_DEALER_TARGET_SERVICE = "target/dealers/";
export const UPLOAD_DEALER_TARGET_BY_REGION = "target/dealer/file-check";
export const ADD_DEALER_TARGET_BY_ID = "target/dealer/update-by-id";

// Ops Calendar
export const GET_ALL_APP_CALENDAR_SERVICE = "app-calendar/all";
export const API_UPLOAD_OPS_CALENDAR = "app-calendar/upload";

// Charts
export const API_ACTUAL_SO_BY_SUB_REGION_GET = "dashboard/v2/regional";
export const API_DEALER_DASHBOARD_GET = "dashboard/dealers";

// Export data
export const API_EXPORT_DEALER_OUTLET_DATA = "dealer-outlets/export";

// Heartbeat Services
export const DOWNLOAD_HEARTBEAT_SERVICE = "/heartbeat/export-heartbeat-summary";
export const SEND_HEARTBEAT_SERVICE = "/heartbeat/send-to-email/heartbeat-summary";

// Media Management (heartbeat)
export const API_GET_MEDIA = "media-management/list";
export const API_GET_MEDIA_BY_ID = "media-management/";
export const API_ADD_MEDIA = "media-management/create";
export const API_UPDATE_MEDIA = "media-management/update";
export const API_PRODUCT_CATEGORY_GROUP = "product-categorization-details/all";
export const API_DELETE_MEDIA = "media-management/";
